import React, {useContext, useEffect, useState} from 'react';
import {
  Button,
  Checkbox, Divider, Form,
  Grid,
  Header, HeaderContent, Icon, Input, Item, Label,
  List,
  Message,
  Placeholder,
  PlaceholderParagraph,
  Segment, Table,
} from 'semantic-ui-react';
import {motion, useAnimation} from 'framer-motion';
import ReservationStepOuter from '../ReservationStepOuter';
import axios from 'axios';
import _ from 'lodash';
import {CommonUtil} from '../../../../utils/CommonUtil';
import moment from 'moment';
import ReservationContext from '../../../../stores/ReservationContext';
import {useLocation, useNavigate} from 'react-router-dom';
import {BASE_STYLES} from '../../../../Consts';

// import {
//   Chart as ChartJS,
//   LinearScale,
//   CategoryScale,
//   BarElement,
//   PointElement,
//   LineElement,
//   Legend,
//   Tooltip,
//   Title,
// } from 'chart.js';
import ChartJS from 'chart.js/auto';
import {Chart} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import CountUp from 'react-countup';
import {isDesktop, isMobile} from 'react-device-detect';

ChartJS.register(
  // LinearScale,
  // CategoryScale,
  // BarElement,
  // PointElement,
  // LineElement,
  // Legend,
  // Tooltip,
  // Title,
  ChartDataLabels,
);

const MY_STEP = 2;
const CHART_OPTIONS = {
  ccp: {
    main: {
      datasets: {
        oprice: {
          label: '평균판매가',
        },
        cprice: {
          label: '카몬판매가',
          borderWidth: 4,
          borderColor: 'rgba(251, 189, 8, 1)',
          backgroundColor: 'rgba(251, 189, 8, 0.8)',
        },
        sprice: {
          label: '상품화 후 평균재판매가',
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
        },
      },
    },
    priceReduction: {
      datasets: {
        price: {
          label: '예상시세',
          borderColor: 'rgba(255, 99, 132, 1)',
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderWidth: 2,
        },
        rate: {
          label: '감가율',
          borderColor: 'rgba(255, 99, 132, 1)',
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderWidth: 2,
        },
        rvalue: {
          label: '잔존가치',
          borderColor: 'rgba(75, 192, 192, 1)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
        },
      },
    },
  },
  c3p: {
    datasets: {
      sell: {
        label: '매도건수',
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
      },
      amountSell: {
        label: '매도평균금액',
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
      },
      buy: {
        label: '매수건수',
        borderColor: 'rgba(255, 159, 64, 1)',
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
      },
      amountBuy: {
        label: '매수평균금액',
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
      },
    },
  },
};

export default function ReservationStep2() {
  const {
    currentStep,
    resetStep,
    backToPrevStep,
    setToMyStep,
    goToNextStep,
    startLoading,
    finishLoading,
  } = useContext(ReservationContext);
  const {pathname, state} = useLocation();
  const {params, step1Data} = state || {};

  const [toNext, setToNext] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [step2Params, setStep2Params] = useState({
    licensePlateNumber: params?.licensePlateNumber,
    carOwnerName: '',
    tsKey: null,
    seriesNo: null,
  });
  const [step2ParamsError, setStep2ParamsError] = useState({
    licensePlateNumber: params?.licensePlateNumber,
    carOwnerName: '',
  });
  const [step2Data, setStep2Data] = useState(null);
  const [errorData, setErrorData] = useState(null);

  const [priceChartData, setPriceChartData] = useState({
    labels: [],
    datasets: [],
  });

  const [priceReductionChartData, setPriceReductionChartData] = useState({
    labels: [],
    datasets: [],
  });

  const [reductionRateChartData, setReductionRateChartData] = useState({
    labels: [],
    datasets: [],
  });

  const [c3pChartData, setC3pChartData] = useState({
    labels: [],
    datasets: [],
  });

  let timer = null;
  const getCarInformationBase = () => {
    setErrorData(null);
    setIsLoading(true);
    timer = setTimeout(() => startLoading(), 3000);

    axios.post('/apis/reservation/step2/base', {
      carOwnerName: step2Params.carOwnerName,
      licensePlateNumber: step2Params.licensePlateNumber,
    })
      .then(({data}) => {
        const {
          needDetailSearch,
          carOwnerName,
          licensePlateNumber,
          tsKey,
          carInformation,
          // carRegister,
          chartData,
        } = data;

        setStep2Data({
          needDetailSearch,
          carOwnerName,
          licensePlateNumber,
          tsKey,
          carInformation,
          // carRegister,
          chartData,
        });
      })
      .catch(({response}) => {
        const {status, data} = response;
        setErrorData(data);
      })
      .finally(() => {
        if (timer !== null) {
          clearTimeout(timer);
          timer = null;
        }

        finishLoading();
        setIsLoading(false);
      });
  };

  const getCarInformationDetail = (isSeriesNoCertain) => {
    setErrorData(null);
    setIsLoading(true);
    timer = setTimeout(() => startLoading(), 3000);

    let uncertainSeriesNo = null;
    if (!isSeriesNoCertain) {
      uncertainSeriesNo = document.querySelector('input[name=seriesNoGroup]:first-child').value;
      setStep2Params(prevState => ({...prevState, seriesNo: uncertainSeriesNo}));
    }

    axios.post('/apis/reservation/step2/detail', {
      carOwnerName: step2Data.carOwnerName,
      licensePlateNumber: step2Data.licensePlateNumber,
      tsKey: step2Data.tsKey,
      seriesNo: isSeriesNoCertain ? step2Params.seriesNo : uncertainSeriesNo,
      //distance: step2Data.carRegister.distance,
    })
      .then(({data}) => {
        const {needDetailSearch, carInformation, chartData} = data;
        setStep2Data(prevState => ({...prevState, needDetailSearch, carInformation, chartData}));
      })
      .catch(({response}) => {
        const {status, data} = response;
        setErrorData(data);
      })
      .finally(() => {
        if (timer !== null) {
          clearTimeout(timer);
          timer = null;
        }
        finishLoading();
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!_.has(step2Data, 'chartData')) return false;

    const {ccp, c3p} = step2Data.chartData;

    if (ccp) {
      const {main, priceReduction} = ccp;

      const mainDatasets = _.transform(_.keys(CHART_OPTIONS.ccp.main.datasets), (res, key) => {
        const datasetOptions = CHART_OPTIONS.ccp.main.datasets[key];
        const dataset = {
          label: CHART_OPTIONS.ccp.main.datasets[key].label,
          data: [main[key]],
        };

        _.has(datasetOptions, 'borderWidth') && (dataset.borderWidth = datasetOptions.borderWidth);
        _.has(datasetOptions, 'borderColor') && (dataset.borderColor = datasetOptions.borderColor);
        _.has(datasetOptions, 'backgroundColor') && (dataset.backgroundColor = datasetOptions.backgroundColor);

        res.push(dataset);

        return res;
      }, []);

      setPriceChartData({
        labels: [''],
        datasets: mainDatasets,
      });

      {
        const {labels: prLabels, data: prData} = priceReduction;

        const labels = _.map(prLabels, label => moment(label).format('YYYY-MM'));
        const options = CHART_OPTIONS.ccp.priceReduction.datasets['price'];
        const dataset = {
          label: CHART_OPTIONS.ccp.priceReduction.datasets['price'].label,
          data: prData['price'],
        };

        _.has(options, 'borderWidth') && (dataset.borderWidth = options.borderWidth);
        _.has(options, 'borderColor') && (dataset.borderColor = options.borderColor);
        _.has(options, 'backgroundColor') && (dataset.backgroundColor = options.backgroundColor);

        setPriceReductionChartData({
          labels,
          datasets: [dataset],
        });

        const datasets = _.transform(['rate', 'rvalue'], (result, key) => {
          const options = CHART_OPTIONS.ccp.priceReduction.datasets[key];
          const dataset = {
            label: CHART_OPTIONS.ccp.priceReduction.datasets[key].label,
            data: prData[key],
            borderWidth: 1,
            anchor: 'end',
            align: 'end',
          };

          _.has(options, 'borderColor') && (dataset.borderColor = options.borderColor);
          _.has(options, 'backgroundColor') && (dataset.backgroundColor = options.backgroundColor);

          result.push(dataset);
        }, []);

        setReductionRateChartData({
          labels,
          datasets,
        });
      }
    }

    if (c3p) {
      const {labels, data} = c3p;

      const datasets = _.transform(_.keys(CHART_OPTIONS.c3p.datasets), (res, key) => {
        const options = CHART_OPTIONS.c3p.datasets[key];
        const dataset = {
          label: CHART_OPTIONS.c3p.datasets[key].label,
          yAxisID: _.startsWith(key, 'amount') ? 'y-amount' : 'y-count',
          data: data[key],
          borderWidth: 1,
          datalabels: {
            anchor: 'end',
            align: 'top',
          },
        };

        _.has(options, 'borderColor') && (dataset.borderColor = options.borderColor);
        _.has(options, 'backgroundColor') && (dataset.backgroundColor = options.backgroundColor);

        res.push(dataset);

        return res;
      }, []);

      setC3pChartData({
        labels: _.map(labels, label => `${label}년식`),
        datasets,
      });
    }
  }, [step2Data?.chartData]);

  useEffect(() => {
    setToNext(step2Params.licensePlateNumber !== '' && step2Params.carOwnerName !== '');
  }, [step2Params.licensePlateNumber, step2Params.carOwnerName]);

  useEffect(() => {
    setToMyStep(MY_STEP);
  }, []);

  return (
    <ReservationStepOuter myStep={MY_STEP} isLoading={isLoading}>
      <Header as={'h3'}>
        <Header.Content>
          [{_.get(step1Data, 'licensePlateNumber')}] 차량의 소유자는 어떻게 되시나요?
          <Header.Subheader>
            소유자명을 입력하세요! 상세한 내 차 정보와 예상 판매 시세를 확인하실 수 있습니다!
          </Header.Subheader>
        </Header.Content>
      </Header>

      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={12}>
            <Form size={'large'}>
              <Form.Field
                fluid
                control={Input}
                disabled={step2Data !== null}
                placeholder="소유자명을 정확히 입력해주세요."
                maxLength={13}
                style={{imeMode: 'inactive', fontSize: BASE_STYLES.FONT_SIZE.SUB}}
                value={step2Params.carOwnerName}
                onChange={(e, {value}) => {
                  const trimmed = value.replace(/\s/g, '');
                  const regExp = /^[ㄱ-ㅎㅏ-ㅣ가-힣ㆍᆢA-Za-z]+$/;
                  setStep2ParamsError(prevState => ({...prevState, carOwnerName: !regExp.test(trimmed)}));
                  setStep2Params(prev => ({...prev, carOwnerName: trimmed}));
                }}
                icon={(step2Params.carOwnerName !== '' && step2Data === null) &&
                <Icon name="remove" link onClick={() => setStep2Params(prevState => ({
                  ...prevState,
                  carOwnerName: '',
                }))} />}
                error={(step2Params.carOwnerName !== '' && step2ParamsError.carOwnerName) && {
                  content: '소유자명을 정확히 입력하세요.',
                  pointing: 'above',
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter' && step2Params.carOwnerName !== '' && !step2ParamsError.carOwnerName) {
                    e.target.blur();
                    getCarInformationBase();
                  }
                }} />
            </Form>
          </Grid.Column>
          <Grid.Column width={4} textAlign={'right'}>
            <Button
              fluid
              size={'large'}
              disabled={!toNext || step2Data !== null || step2ParamsError.carOwnerName}
              content={'조회'}
              icon={'search'}
              color={'yellow'}
              onClick={() => getCarInformationBase()} />
          </Grid.Column>
        </Grid.Row>

        {
          (step2Data === null) &&
          <Grid.Row>
            <Grid.Column>
              <Message>
                <Message.Content>
                  <Message.Header>
                    <Icon name={'user outline'} /> 차량의 상세한 정보를 확인하기 위해 차량의 실제 소유자 정보가 필요합니다.
                  </Message.Header>
                  <Message.List>
                    <List bulleted>
                      <List.Item>자동차 등록증상의 소유자명을 입력해주세요.</List.Item>
                      <List.Item>공동 명의일 경우 대표자 1명의 이름을 입력해주세요.</List.Item>
                      <List.Item>고객님의 차량 판매를 위해 정확한 차량정보를 확인하는 목적으로만 사용됩니다.</List.Item>
                    </List>
                  </Message.List>
                </Message.Content>
              </Message>
            </Grid.Column>
          </Grid.Row>
        }

        {
          (step2Data !== null) &&
          (
            step2Data?.needDetailSearch
              ?
              (
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <Message icon>
                      <Icon name={'hand point up outline'} />
                      <Message.Content>
                        <Header as={'h4'}>
                          <Header.Content>
                            {/*조회된 [{step2Data.licensePlateNumber}] 차량은 [{step2Data.carRegister.carName}] 입니다.<br />*/}
                            조회된 [{step2Data.licensePlateNumber}] 차량은 [{_.get(step1Data, 'data.carName')}] 입니다.<br />
                            해당 차랑은 다양한 세부 모델이 있습니다.<br />
                            정확한 차량 시세 확인과 원활한 예약 진행을 위해 세부 모델을 선택해주세요.
                          </Header.Content>
                          {
                            step1Data.count > 0 &&
                            <p>
                              <Header.Subheader>
                                카몬에서 확인한 모델은 <span
                                style={{fontWeight: 'bold'}}>[ {_.get(step1Data, 'data.carOpt')} ]</span> 입니다.<br />
                                {/*확실하지 않으시다면, 아래 '확실하지 않아요.'를 버튼을 {isDesktop ? '클릭' : '탭'}하세요.*/}
                              </Header.Subheader>
                            </p>
                          }
                        </Header>
                        <Divider />
                        {_.map(_.get(step2Data.carInformation, 'modellist'), (model, modelIdx) => {
                          return (
                            <React.Fragment key={`MD_${modelIdx}`}>
                              <Header as={'h5'}>{model.modelname}</Header>
                              <List>
                                {_.map(_.get(model, 'serieslist'), (series, seriesIdx) => (
                                  <List.Item key={`MD_${modelIdx}_SE_${seriesIdx}`}>
                                    <Checkbox
                                      radio
                                      label={`${seriesIdx + 1}. ${series.seriesname} (출고가: ${CommonUtil.Unit.format(series.seriesprice)}원)`}
                                      name="seriesNoGroup"
                                      datatext={series.seriesname}
                                      value={series.seriesno}
                                      checked={step2Params.seriesNo === series.seriesno}
                                      onClick={(e, {datatext, value, checked}) => {
                                        setStep2Params(prevState => ({
                                          ...prevState,
                                          seriesName: checked ? datatext : null,
                                          seriesNo: checked ? value : null,
                                        }));
                                      }}
                                    />
                                  </List.Item>
                                ))}
                              </List>
                            </React.Fragment>
                          );
                        })}
                        <Divider />
                        <Grid stackable>
                          <Grid.Row columns={'equal'}>
                            {
                              step2Params?.seriesName &&
                              <Grid.Column>
                                <Header as={'h5'}>선택하신 모델은 [{step2Params.seriesName}] 입니다.</Header>
                              </Grid.Column>
                            }
                            <Grid.Column textAlign={'right'}>
                              <Button color={'yellow'} disabled={step2Params.seriesNo === null} content={'선택'}
                                      icon={'check'}
                                      onClick={() => getCarInformationDetail(true)} />
                              {/*<Button disabled={step2Params.seriesNo !== null} content={'확실하지 않아요.'} icon={'question'}*/}
                              {/*        onClick={() => getCarInformationDetail(false)} />*/}
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Message.Content>
                    </Message>
                  </Grid.Column>
                </Grid.Row>
              )
              :
              (
                <>
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <Header as={'h3'} dividing>
                        <Icon name={'info circle'} />
                        <Header.Content>
                          차량상세정보
                          <Header.Subheader>
                            [{_.get(step2Data, 'carInformation.cardata.carnum')}] 차량의 정보를 알려드립니다!
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                      <Item.Group>
                        <Item>
                          <Item.Image
                            src={_.get(step2Data, 'carInformation.cardata.modelimage')}
                            style={{
                              boxShadow: '1px 1px 5px 1px rgba(0,0,0,0.2)',
                              display: 'flex',
                              alignItems: 'center',
                            }} />
                          <Item.Content>
                            <Item.Header as="a">{_.get(step2Data, 'carInformation.cardata.regname')}</Item.Header>
                            <Item.Meta>
                              {_.get(step2Data, 'carInformation.cardata.cargubun') || '정보없음'} | {_.get(step2Data, 'carInformation.cardata.makername')} | {_.get(step2Data, 'carInformation.cardata.modelname')}
                            </Item.Meta>
                            <Item.Description>
                              <Table basic={'very'} compact collapsing style={{width: '100%'}}>
                                <Table.Header />
                                <Table.Body>
                                  <Table.Row>
                                    <Table.Cell>등급</Table.Cell>
                                    <Table.Cell>
                                      {_.get(step2Data, 'carInformation.cardata.seriesname')} ({_.get(step2Data, 'carInformation.cardata.seriesname1')} {_.get(step2Data, 'carInformation.cardata.seriesname2')})
                                    </Table.Cell>
                                  </Table.Row>
                                  <Table.Row>
                                    <Table.Cell>연식</Table.Cell>
                                    <Table.Cell>{_.get(step2Data, 'carInformation.cardata.year')}년</Table.Cell>
                                  </Table.Row>
                                  <Table.Row>
                                    <Table.Cell>최초등록</Table.Cell>
                                    <Table.Cell>{moment(_.get(step2Data, 'carInformation.cardata.firstdate')).format('YYYY년 MM월 DD일')}</Table.Cell>
                                  </Table.Row>
                                  <Table.Row>
                                    <Table.Cell>신차가격</Table.Cell>
                                    <Table.Cell>
                                      {_.get(step2Data, 'carInformation.cardata.newprice')
                                        ?
                                        <>
                                          {CommonUtil.Unit.format(_.get(step2Data, 'carInformation.cardata.newprice'))}원
                                        </>
                                        : '정보없음'
                                      }
                                    </Table.Cell>
                                  </Table.Row>
                                </Table.Body>
                              </Table>
                            </Item.Description>
                            <Item.Extra>
                              <Label>{_.get(step2Data, 'carInformation.cardata.gearbox') || '정보없음'}</Label>
                              <Label>{_.get(step2Data, 'carInformation.cardata.fuel') || '정보없음'}</Label>
                              <Label>{_.get(step2Data, 'carInformation.cardata.color') || '정보없음'}</Label>
                            </Item.Extra>
                          </Item.Content>
                        </Item>
                      </Item.Group>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as={'h3'} dividing>
                        <Icon name={'chart bar'} />
                        <Header.Content>
                          예상판매시세
                          <Header.Subheader>
                            [{_.get(step2Data, 'carInformation.cardata.carnum')}] 차량의 예상판매금액을 알려드립니다!
                          </Header.Subheader>
                        </Header.Content>
                      </Header>

                      <Grid stackable centered>
                        <Grid.Row>
                          <Grid.Column textAlign={'center'}>
                            <Header as={'h1'} style={{lineHeight: 1.1}}>
                              <div>예상 판매가는&nbsp;</div>
                              <div className={'price-title'}>
                                <CountUp end={_.get(step2Data, 'chartData.ccp.main.oprice') / 10000} separator={','} />
                                ~
                                <CountUp end={_.get(step2Data, 'chartData.ccp.main.cprice') / 10000} separator={','} />만원
                              </div>
                              <div>입니다.</div>
                            </Header>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column>
                            <Message error>
                              <Header as="h3">
                                ※ 차량 진단 결과가 반영되기 전의 예상 판매가로, 실제 판매가와는 차이가 있을 수 있습니다.
                              </Header>
                            </Message>

                            <Message info style={{fontSize: 16}}>
                              <Message.Header>
                                <Icon name="exclamation triangle" />꼭! 읽어보세요, 카몬의 판매가가 높은 이유.
                              </Message.Header>
                              <Message.Content>
                                <p style={{wordWrap: 'break-word', 'wordBreak': 'keep-all'}}>
                                  보통, 중고차 딜러는 차량 판매자로부터 차량을 구매하기 위해서 다음과 같은 절차를 거칩니다.
                                </p>
                                <List bulleted>
                                  <List.Item style={{wordWrap: 'break-word', 'wordBreak': 'keep-all'}}>
                                    중고차 시장에서 구매 대상 차량을 찾거나, 판매자의 연락을 받습니다.
                                  </List.Item>
                                  <List.Item style={{wordWrap: 'break-word', 'wordBreak': 'keep-all'}}>
                                    차량 판매자와 지속적인 연락을 하고, 차량을 직접 보기 위해 시간과 비용을 들입니다.
                                  </List.Item>
                                  <List.Item style={{wordWrap: 'break-word', 'wordBreak': 'keep-all'}}>
                                    차량의 상태가 생각보다 나쁘거나, 원하는 가격에 맞지 않는 등의 이유로 차량 구매에 실패하면, 이 때 들였던 시간과 비용이 손실이 됩니다.
                                  </List.Item>
                                  <List.Item style={{wordWrap: 'break-word', 'wordBreak': 'keep-all'}}>
                                    이러한 시간, 비용의 손실을 줄이기 위해 부당한 감가를 시도할 여지가 많아집니다.
                                  </List.Item>
                                </List>
                                <p style={{wordWrap: 'break-word', 'wordBreak': 'keep-all'}}>
                                  카몬에서는 국가 공인 정비소의 <b>사전 성능 진단을 통해 판매자와 딜러에게 명확한 정보를 제공하여</b>, 딜러에게 발생할 수 있는 시간과 비용의 손실을
                                  줄여줍니다.<br />
                                  그래서, 그 <b>비용이 차량의 판매가에 반영되도록</b> 하여, 판매가를 높이고 있습니다.
                                </p>
                              </Message.Content>
                            </Message>
                          </Grid.Column>
                        </Grid.Row>
                        {/*<Grid.Row>
                          <Grid.Column width={12}>
                            <Header as={'h4'} attached={'top'}>
                              <Header.Content>
                                예상 판매가
                                <Header.Subheader>
                                  (차량 진단 결과가 반영되기 전의 예상 판매가로, 실제 판매가와 다소 차이가 있을 수 있습니다.)
                                </Header.Subheader>
                              </Header.Content>
                            </Header>
                            <Segment attached>
                              <Chart
                                {...(isMobile && {
                                  height: 260,
                                })}
                                type={'bar'}
                                data={priceChartData}
                                options={{
                                  indexAxis: 'y',
                                  elements: {
                                    bar: {
                                      borderWidth: 2,
                                    },
                                  },
                                  responsive: true,
                                  maintainAspectRatio: !isMobile,
                                  plugins: {
                                    legend: {
                                      position: 'bottom',
                                    },
                                    datalabels: {
                                      display: true,
                                      font: (context) => {
                                        const {
                                          dataset: {
                                            label,
                                          },
                                        } = context;

                                        if (label === '카몬판매가') {
                                          return {
                                            size: 20,
                                            weight: 'bold',
                                          };
                                        } else {
                                          return {
                                            weight: 'normal',
                                          };
                                        }
                                      },
                                      formatter: (value) => `${CommonUtil.Unit.format(value / 10000)}만원 예상`,
                                    },
                                  },
                                  scales: {
                                    x: {
                                      ticks: {
                                        callback: (value, index, ticks) => CommonUtil.Unit.format(value / (isMobile ? 10000 : 1)),
                                      },
                                      grace: 100000,
                                    },
                                  },
                                }} />
                            </Segment>
                            <Message attached={'bottom'} info>
                              <Message.Header>
                                꼭, 읽어보세요, 카몬의 판매가가 높은 이유.
                              </Message.Header>
                              <Message.Content>
                                <p style={{wordWrap: 'break-word', 'wordBreak': 'keep-all'}}>
                                  보통, 중고차 딜러는 차량 판매자로부터 차량을 구매하기 위해서 다음과 같은 절차를 거칩니다.
                                </p>
                                <List bulleted>
                                  <List.Item style={{wordWrap: 'break-word', 'wordBreak': 'keep-all'}}>
                                    중고차 시장에서 구매 대상 차량을 찾거나, 판매자의 연락을 받습니다.
                                  </List.Item>
                                  <List.Item style={{wordWrap: 'break-word', 'wordBreak': 'keep-all'}}>
                                    차량 판매자와 지속적인 연락을 하고, 차량을 직접 보기 위해 시간과 비용을 들입니다.
                                  </List.Item>
                                  <List.Item style={{wordWrap: 'break-word', 'wordBreak': 'keep-all'}}>
                                    차량의 상태가 생각보다 나쁘거나, 원하는 가격에 맞지 않는 등의 이유로 차량 구매에 실패하면, 이 때 들였던 시간과 비용이 손실이 됩니다.
                                  </List.Item>
                                  <List.Item style={{wordWrap: 'break-word', 'wordBreak': 'keep-all'}}>
                                    이러한 시간, 비용의 손실을 줄이기 위해 부당한 감가를 시도할 여지가 많아집니다.
                                  </List.Item>
                                </List>
                                <p style={{wordWrap: 'break-word', 'wordBreak': 'keep-all'}}>
                                  카몬에서는 국가 공인 정비소의 <b>사전 성능 진단을 통해 판매자와 딜러에게 명확한 정보를 제공하여</b>, 딜러에게 발생할 수 있는 시간과 비용의 손실을
                                  줄여줍니다.<br />
                                  그래서, 그 <b>비용이 차량의 판매가에 반영되도록</b> 하여, 판매가를 높이고 있습니다.
                                </p>
                              </Message.Content>
                            </Message>

                            <Header as={'h4'} attached={'top'}>
                              <Header.Content>
                                동일 모델 실거래가
                                <Header.Subheader>
                                  최근 1년간의 자동차양도증명서 상의 신고가 기준입니다.
                                </Header.Subheader>
                              </Header.Content>
                            </Header>
                            <Segment attached={'bottom'}>
                              <Chart
                                {...(isMobile && {
                                  height: 220,
                                })}
                                type={'bar'}
                                data={c3pChartData}
                                options={{
                                  responsive: true,
                                  maintainAspectRatio: !isMobile,
                                  interaction: {
                                    mode: 'index',
                                    intersect: false,
                                  },
                                  stacked: false,
                                  plugins: {
                                    legend: {
                                      position: 'bottom',
                                    },
                                    datalabels: {
                                      display: !isMobile,
                                      backgroundColor: (context) => context.dataset.borderColor,
                                      borderRadius: 4,
                                      color: '#ffffff',
                                      formatter: (value) => CommonUtil.Unit.format(value),
                                    },
                                  },
                                  scales: {
                                    x: {
                                      title: {
                                        display: false,
                                      },
                                    },
                                    'y-count': {
                                      type: 'linear',
                                      position: 'left',
                                      title: {
                                        display: false,
                                        text: '건수',
                                      },
                                      grid: {
                                        display: true,
                                        drawOnChartArea: true,
                                      },
                                      grace: 2,
                                    },
                                    'y-amount': {
                                      type: 'linear',
                                      position: 'right',
                                      title: {
                                        display: false,
                                        text: '금액',
                                      },
                                      grid: {
                                        display: true,
                                        drawOnChartArea: true,
                                      },
                                      ticks: {
                                        callback: (value, index, ticks) => CommonUtil.Unit.format(value / (isMobile ? 10000 : 1)),
                                      },
                                      grace: 200000,
                                    },
                                  },
                                }} />
                            </Segment>

                            <Header as={'h4'} attached={'top'}>
                              <Header.Content>
                                자연 감가 예측
                                <Header.Subheader>
                                  아쉽게도, 시간이 지날수록 소중한 내 차의 가치는 떨어지기 마련입니다. <Icon name={'meh outline'} /><br />
                                  지금 바로 카몬에 예약하세요! <Icon name={'smile outline'} />
                                </Header.Subheader>
                              </Header.Content>
                            </Header>
                            <Segment attached>
                              <Chart
                                {...(isMobile && {
                                  height: 220,
                                })}
                                type={'line'}
                                data={priceReductionChartData}
                                options={{
                                  responsive: true,
                                  maintainAspectRatio: !isMobile,
                                  interaction: {
                                    mode: 'index',
                                    intersect: false,
                                  },
                                  stacked: false,
                                  plugins: {
                                    legend: {
                                      position: 'bottom',
                                    },
                                    datalabels: {
                                      display: (context) => (context.dataIndex % (!isMobile ? 10 : 20) === 0),
                                      backgroundColor: (context) => context.dataset.borderColor,
                                      borderRadius: 4,
                                      color: '#ffffff',
                                      formatter: (value) => CommonUtil.Unit.format(value),
                                    },
                                  },
                                  scales: {
                                    amount: {
                                      type: 'linear',
                                      display: true,
                                      position: 'right',
                                      ticks: {
                                        callback: (value, index, ticks) => CommonUtil.Unit.format(value / (isMobile ? 10000 : 1)),
                                      },
                                      grace: 100000,
                                    },
                                  },
                                }} />
                            </Segment>
                            <Header as={'h4'} attached>
                              <Header.Content>
                                감가율 / 잔존가치
                                <Header.Subheader>
                                </Header.Subheader>
                              </Header.Content>
                            </Header>
                            <Segment attached={'bottom'}>
                              <Chart
                                {...(isMobile && {
                                  height: 220,
                                })}
                                type={'line'}
                                data={reductionRateChartData}
                                options={{
                                  responsive: true,
                                  maintainAspectRatio: !isMobile,
                                  interaction: {
                                    mode: 'index',
                                    intersect: false,
                                  },
                                  stacked: false,
                                  plugins: {
                                    legend: {
                                      position: 'bottom',
                                    },
                                    datalabels: {
                                      display: (context) => (context.dataIndex % (!isMobile ? 10 : 20) === 0),
                                      backgroundColor: (context) => context.dataset.borderColor,
                                      borderRadius: 4,
                                      color: '#ffffff',
                                      formatter: value => value.toFixed(2),
                                    },
                                  },
                                  scales: {
                                    ratio: {
                                      type: 'linear',
                                      display: true,
                                      position: 'right',
                                      grid: {
                                        drawOnChartArea: false,
                                      },
                                    },
                                  },
                                }} />
                            </Segment>
                          </Grid.Column>
                        </Grid.Row>*/}
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </>
              )
          )
        }
        {
          (errorData !== null) &&
          <Grid.Row>
            <Grid.Column>
              <Message negative icon>
                <Icon name={'meh outline'} />
                <Message.Content>
                  <Message.Header>[{_.get(step1Data, 'licensePlateNumber')}] 차량 정보를 확인하지 못했습니다. 다시 한번 시도해
                    보세요.</Message.Header>
                  <p>{_.join(_.get(errorData, 'details'), ' ')}</p>
                </Message.Content>
              </Message>
            </Grid.Column>
          </Grid.Row>
        }
        <Grid.Row>
          <Grid.Column textAlign={'right'}>
            <Button onClick={() => {
              backToPrevStep({
                params,
                step1Data,
              });
            }}>
              <Icon name={'angle left'} fitted /> 이전
            </Button>
            <Button color={'teal'} disabled={_.isEmpty(_.get(step2Data, 'carInformation.cardata'))} onClick={() => {
              goToNextStep({
                params: _.merge(params, step2Params),
                step1Data,
                step2Data,
              });
            }}>
              다음 <Icon name={'angle right'} fitted />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ReservationStepOuter>
  );
}