import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Item,
  Label,
  List,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react';

import axios from 'axios';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash';
import moment from 'moment';
import CountUp from 'react-countup';
import { useLocation } from 'react-router-dom';
import { BASE_STYLES } from '../../../../Consts';
import ReservationContext from '../../../../stores/ReservationContext';
import { CommonUtil } from '../../../../utils/CommonUtil';
import ReservationStepOuter from '../ReservationStepOuter';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  Title,
  ChartDataLabels
);

const MY_STEP = 1;
const CHART_OPTIONS = {
  ccp: {
    main: {
      datasets: {
        oprice: {
          label: '평균판매가',
        },
        cprice: {
          label: '카몬판매가',
          borderWidth: 4,
          borderColor: 'rgba(251, 189, 8, 1)',
          backgroundColor: 'rgba(251, 189, 8, 0.8)',
        },
        sprice: {
          label: '상품화 후 평균재판매가',
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
        },
      },
    },
    priceReduction: {
      datasets: {
        price: {
          label: '예상시세',
          borderColor: 'rgba(255, 99, 132, 1)',
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderWidth: 2,
        },
        rate: {
          label: '감가율',
          borderColor: 'rgba(255, 99, 132, 1)',
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderWidth: 2,
        },
        rvalue: {
          label: '잔존가치',
          borderColor: 'rgba(75, 192, 192, 1)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
        },
      },
    },
  },
  c3p: {
    datasets: {
      sell: {
        label: '매도건수',
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
      },
      amountSell: {
        label: '매도평균금액',
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
      },
      buy: {
        label: '매수건수',
        borderColor: 'rgba(255, 159, 64, 1)',
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
      },
      amountBuy: {
        label: '매수평균금액',
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
      },
    },
  },
};

export default function NewReservationStep1({ style: outerStyle }) {
  const { goToNextStep, startLoading, finishLoading, setToMyStep, buildButtons } = useContext(ReservationContext);
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();
  const [step1Params, setStep1Params] = useState({
    licensePlateNumber: '',
    carOwnerName: '',
    tsKey: null,
    seriesNo: null,
    saleType: state?.saleType,
  });
  const [step1ParamsError, setStep1ParamsError] = useState({
    licensePlateNumber: false,
    carOwnerName: false,
  });
  const [step1Data, setStep1Data] = useState(null);
  const [step1GuessData, setStep1GuessData] = useState(null);
  const [errorData, setErrorData] = useState(null);

  const licensePlateNumberRef = useRef(null);
  const [showLicensePlateNumberError, setShowLicensePlateNumberError] = useState(false);
  const carOwnerNameRef = useRef(null);
  const [showCarOwnerNameError, setShowCarOwnerNameError] = useState(false);

  const [priceChartData, setPriceChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [priceReductionChartData, setPriceReductionChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [reductionRateChartData, setReductionRateChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [c3pChartData, setC3pChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    if (process.env.RUN_MODE === 'development') {
      setStep1Params((prevState) => ({ ...prevState, licensePlateNumber: '27주0518', carOwnerName: '배진이' }));
    }
  }, []);

  let timer = null;
  const checkCarExists = () => {
    axios
      .post('/apis/reservation/step1', {
        licensePlateNumber: step1Params.licensePlateNumber,
      })
      .then(({ data }) => {
        setStep1GuessData(data);
      })
      .catch(({ response }) => {
        console.error(response);
      });
  };

  const getCarInformationBase = () => {
    setErrorData(null);

    setIsLoading(true);
    timer = setTimeout(() => startLoading(), 3000);

    axios
      .post('/apis/reservation/step2/base', {
        carOwnerName: step1Params.carOwnerName,
        licensePlateNumber: step1Params.licensePlateNumber,
      })
      .then(({ data }) => {
        const {
          needDetailSearch,
          carOwnerName,
          licensePlateNumber,
          tsKey,
          carInformation,
          checkPrice,
          // carRegister,
          chartData,
        } = data;

        setStep1Data({
          needDetailSearch,
          carOwnerName,
          licensePlateNumber,
          tsKey,
          carInformation,
          checkPrice,
          // carRegister,
          chartData,
        });

        if (needDetailSearch) {
          checkCarExists();
        } else {
          setTimeout(() => {
            const top =
              window.scrollY +
              document.querySelector('#car-detail-info-row').getBoundingClientRect().y -
              BASE_STYLES.NAVBAR_HEIGHT;
            window.scrollTo({ top: top, left: 0, behavior: 'smooth' });
          }, 100);
        }
      })
      .catch(({ response }) => {
        const { status, data } = response;
        setErrorData(data);

        setTimeout(() => {
          const top =
            window.scrollY +
            document.querySelector('#row-error-data').getBoundingClientRect().y -
            BASE_STYLES.NAVBAR_HEIGHT;
          window.scrollTo({ top: top, left: 0, behavior: 'smooth' });
        }, 100);
      })
      .finally(() => {
        if (timer !== null) {
          clearTimeout(timer);
          timer = null;
        }

        finishLoading();
        setIsLoading(false);
      });
  };

  const getCarInformationDetail = (isSeriesNoCertain) => {
    setErrorData(null);
    setIsLoading(true);
    timer = setTimeout(() => startLoading(), 3000);

    let uncertainSeriesNo = null;
    if (!isSeriesNoCertain) {
      uncertainSeriesNo = document.querySelector('input[name=seriesNoGroup]:first-child').value;
      setStep1Params((prevState) => ({
        ...prevState,
        seriesNo: uncertainSeriesNo,
      }));
    }

    axios
      .post('/apis/reservation/step2/detail', {
        carOwnerName: step1Data.carOwnerName,
        licensePlateNumber: step1Data.licensePlateNumber,
        tsKey: step1Data.tsKey,
        seriesNo: isSeriesNoCertain ? step1Params.seriesNo : uncertainSeriesNo,
      })
      .then(({ data }) => {
        const { needDetailSearch, carInformation, chartData } = data;
        setStep1Data((prevState) => ({
          ...prevState,
          needDetailSearch,
          carInformation,
          chartData,
        }));
      })
      .catch(({ response }) => {
        const { status, data } = response;
        setErrorData(data);
      })
      .finally(() => {
        if (timer !== null) {
          clearTimeout(timer);
          timer = null;
        }
        finishLoading();
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!_.has(step1Data, 'chartData')) return;

    const { ccp, c3p } = step1Data.chartData;

    if (ccp) {
      const { main, priceReduction } = ccp;

      const mainDatasets = _.transform(
        _.keys(CHART_OPTIONS.ccp.main.datasets),
        (res, key) => {
          const datasetOptions = CHART_OPTIONS.ccp.main.datasets[key];
          const dataset = {
            label: CHART_OPTIONS.ccp.main.datasets[key].label,
            data: [main[key]],
          };

          _.has(datasetOptions, 'borderWidth') && (dataset.borderWidth = datasetOptions.borderWidth);
          _.has(datasetOptions, 'borderColor') && (dataset.borderColor = datasetOptions.borderColor);
          _.has(datasetOptions, 'backgroundColor') && (dataset.backgroundColor = datasetOptions.backgroundColor);

          res.push(dataset);

          return res;
        },
        []
      );

      setPriceChartData({
        labels: [''],
        datasets: mainDatasets,
      });

      const { labels: prLabels, data: prData } = priceReduction;

      const labels = _.map(prLabels, (label) => moment(label).format('YYYY-MM'));
      const options = CHART_OPTIONS.ccp.priceReduction.datasets['price'];
      const dataset = {
        label: CHART_OPTIONS.ccp.priceReduction.datasets['price'].label,
        data: prData['price'],
      };

      _.has(options, 'borderWidth') && (dataset.borderWidth = options.borderWidth);
      _.has(options, 'borderColor') && (dataset.borderColor = options.borderColor);
      _.has(options, 'backgroundColor') && (dataset.backgroundColor = options.backgroundColor);

      setPriceReductionChartData({
        labels,
        datasets: [dataset],
      });

      const datasets = _.transform(
        ['rate', 'rvalue'],
        (result, key) => {
          const options = CHART_OPTIONS.ccp.priceReduction.datasets[key];
          const dataset = {
            label: CHART_OPTIONS.ccp.priceReduction.datasets[key].label,
            data: prData[key],
            borderWidth: 1,
            anchor: 'end',
            align: 'end',
          };

          _.has(options, 'borderColor') && (dataset.borderColor = options.borderColor);
          _.has(options, 'backgroundColor') && (dataset.backgroundColor = options.backgroundColor);

          result.push(dataset);
        },
        []
      );

      setReductionRateChartData({
        labels,
        datasets,
      });
    }

    if (c3p) {
      const { labels, data } = c3p;

      const datasets = _.transform(
        _.keys(CHART_OPTIONS.c3p.datasets),
        (res, key) => {
          const options = CHART_OPTIONS.c3p.datasets[key];
          const dataset = {
            label: CHART_OPTIONS.c3p.datasets[key].label,
            yAxisID: _.startsWith(key, 'amount') ? 'y-amount' : 'y-count',
            data: data[key],
            borderWidth: 1,
            datalabels: {
              anchor: 'end',
              align: 'top',
            },
          };

          _.has(options, 'borderColor') && (dataset.borderColor = options.borderColor);
          _.has(options, 'backgroundColor') && (dataset.backgroundColor = options.backgroundColor);

          res.push(dataset);

          return res;
        },
        []
      );

      setC3pChartData({
        labels: _.map(labels, (label) => `${label}년식`),
        datasets,
      });
    }
  }, [step1Data?.chartData]);

  useEffect(() => {
    buildButtons([
      <Button
        key='btn-back-prev'
        size={isDesktop ? 'large' : 'small'}
        disabled={_.isEmpty(step1Data) || isLoading}
        icon={'undo'}
        content={'다시하기'}
        onClick={() => {
          setStep1Params({
            licensePlateNumber: '',
            carOwnerName: '',
            tsKey: null,
            seriesNo: null,
          });
          setStep1Data(null);
          setStep1ParamsError({
            licensePlateNumber: false,
            carOwnerName: false,
          });

          if (isDesktop) setTimeout(() => licensePlateNumberRef.current.querySelector('input').focus(), 100);
        }}
      />,
      <Button
        key='btn-go-next'
        size={isDesktop ? 'large' : 'small'}
        color={'yellow'}
        disabled={_.isEmpty(_.get(step1Data, 'carInformation.cardata')) || isLoading}
        loading={isLoading}
        onClick={() => {
          goToNextStep({
            params: step1Params,
            step1Data,
          });
        }}
      >
        예약 날짜 선택 <Icon name={'angle right'} fitted />
      </Button>,
    ]);
  }, [step1Params, step1Data, isLoading]);

  useLayoutEffect(() => {
    if (isDesktop) setTimeout(() => licensePlateNumberRef.current.querySelector('input').focus(), 100);
  }, []);

  useEffect(() => setToMyStep(MY_STEP), []);

  return (
    <ReservationStepOuter myStep={MY_STEP} isLoading={isLoading}>
      <Header as={isDesktop ? 'h2' : 'h3'}>
        <Header.Content>
          차량번호와 소유자 이름을 입력하세요!
          <Header.Subheader>
            차량번호와 소유자 이름만 입력하시면, 내 차 정보와 시세를 확인하실 수 있습니다!
          </Header.Subheader>
        </Header.Content>
      </Header>

      <Form size='large'>
        <Grid stackable>
          <Grid.Row centered stretched>
            <Grid.Column width={10}>
              <div ref={licensePlateNumberRef}>
                <Form.Field
                  control={Input}
                  fluid
                  placeholder='차량번호를 입력하세요.'
                  size='large'
                  id='license-number-plate-text'
                  className='license-number-plate-inner'
                  maxLength={11}
                  style={{
                    imeMode: 'inactive',
                    fontSize: BASE_STYLES.FONT_SIZE.SUB,
                  }}
                  value={step1Params.licensePlateNumber}
                  onChange={(e, { value }) => {
                    if (errorData !== null) setErrorData(null);
                    setShowLicensePlateNumberError(false);
                    setShowCarOwnerNameError(false);

                    const trimmed = value.replace(/\s/g, '');
                    setStep1ParamsError((prevState) => ({
                      ...prevState,
                      licensePlateNumber: !CommonUtil.Validator.validateLicensePlateNumber(trimmed),
                    }));
                    setStep1Params((prevState) => ({
                      ...prevState,
                      licensePlateNumber: trimmed,
                    }));
                  }}
                  disabled={!_.isEmpty(step1Data)}
                  icon={
                    step1Params.licensePlateNumber !== '' &&
                    _.isEmpty(step1Data) && (
                      <Icon
                        name='remove'
                        link
                        onClick={() => {
                          if (errorData !== null) setErrorData(null);
                          setShowLicensePlateNumberError(false);
                          setShowCarOwnerNameError(false);

                          setStep1Params((prevState) => ({
                            ...prevState,
                            licensePlateNumber: '',
                          }));
                        }}
                      />
                    )
                  }
                  error={
                    showLicensePlateNumberError
                      ? step1Params.licensePlateNumber !== '' && step1ParamsError.licensePlateNumber
                        ? {
                            content: '올바른 차량번호를 입력하세요.',
                            pointing: 'above',
                          }
                        : false
                      : step1Params.licensePlateNumber !== '' && step1ParamsError.licensePlateNumber
                  }
                  onKeyPress={(e) => {
                    if (
                      e.key === 'Enter' &&
                      step1Params.licensePlateNumber !== '' &&
                      !step1ParamsError.licensePlateNumber
                    ) {
                      e.target.blur();
                      carOwnerNameRef.current.querySelector('input').focus();
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column width={10}>
              <div ref={carOwnerNameRef}>
                <Form.Field
                  fluid
                  label='소유자 이름'
                  control={Input}
                  disabled={step1Data !== null}
                  placeholder='소유자 이름을 입력하세요.'
                  maxLength={13}
                  style={{
                    imeMode: 'inactive',
                    fontSize: BASE_STYLES.FONT_SIZE.SUB,
                  }}
                  value={step1Params.carOwnerName}
                  onChange={(e, { value }) => {
                    if (errorData !== null) setErrorData(null);
                    setShowLicensePlateNumberError(false);
                    setShowCarOwnerNameError(false);

                    const trimmed = value.replace(/\s/g, '');
                    const regExp = /^[ㄱ-ㅎㅏ-ㅣ가-힣ㆍᆢA-Za-z,()]+$/;
                    setStep1ParamsError((prevState) => ({
                      ...prevState,
                      carOwnerName: !regExp.test(trimmed),
                    }));
                    setStep1Params((prev) => ({
                      ...prev,
                      carOwnerName: trimmed,
                    }));
                  }}
                  icon={
                    step1Params.carOwnerName !== '' &&
                    step1Data === null && (
                      <Icon
                        name='remove'
                        link
                        onClick={() => {
                          if (errorData !== null) setErrorData(null);
                          setShowLicensePlateNumberError(false);
                          setShowCarOwnerNameError(false);

                          setStep1Params((prevState) => ({
                            ...prevState,
                            carOwnerName: '',
                          }));
                        }}
                      />
                    )
                  }
                  error={
                    showCarOwnerNameError
                      ? step1Params.carOwnerName !== '' && step1ParamsError.carOwnerName
                        ? {
                            content: '소유자 이름을 정확히 입력하세요.',
                            pointing: 'above',
                          }
                        : false
                      : step1Params.carOwnerName !== '' && step1ParamsError.carOwnerName
                  }
                  onKeyPress={(e) => {
                    if (
                      e.key === 'Enter' &&
                      step1Params.carOwnerName !== '' &&
                      !step1ParamsError.carOwnerName &&
                      step1Params.licensePlateNumber !== '' &&
                      !step1ParamsError.licensePlateNumber
                    ) {
                      e.target.blur();
                      getCarInformationBase();
                    }
                  }}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column width={10}>
              <Button
                fluid
                size={'large'}
                content={'차량정보확인'}
                disabled={
                  step1Params.licensePlateNumber === '' || step1Params.carOwnerName === '' || !_.isEmpty(step1Data)
                }
                icon={'search'}
                color={'yellow'}
                onClick={() => {
                  const isCarOwnerNameInvalid = step1Params.carOwnerName === '' || step1ParamsError.carOwnerName;
                  const isLicensePlateNumberInvalid =
                    step1Params.licensePlateNumber === '' || step1ParamsError.licensePlateNumber;

                  if (step1Data !== null || isLicensePlateNumberInvalid || isCarOwnerNameInvalid) {
                    setShowLicensePlateNumberError(isLicensePlateNumberInvalid);
                    setShowCarOwnerNameError(isCarOwnerNameInvalid);
                    return;
                  }

                  getCarInformationBase();
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>

      <Grid stackable>
        {step1Data === null && (
          <Grid.Row centered>
            <Grid.Column width={10}>
              <Message size={isDesktop ? 'large' : 'small'} icon={isDesktop}>
                {isDesktop && <Icon name={'question circle'} />}
                <Message.Content style={{ wordBreak: 'keep-all' }}>
                  <Message.Header>
                    {isMobile && <Icon name='question circle' />}소유자 이름은 왜 필요한가요?
                  </Message.Header>
                  차량의 상세한 정보를 확인하기 위해 차량의 실제 소유자 정보가 필요합니다.
                  <Message.List>
                    <List bulleted>
                      <List.Item>자동차 등록증상의 소유자 이름을 입력해주세요.</List.Item>
                      <List.Item>공동 명의일 경우 대표자 1명의 이름을 입력해주세요.</List.Item>
                      <List.Item>고객님의 차량 판매를 위해 정확한 차량정보를 확인하는 목적으로만 사용됩니다.</List.Item>
                    </List>
                  </Message.List>
                </Message.Content>
              </Message>
            </Grid.Column>
          </Grid.Row>
        )}

        {step1Data !== null &&
          (step1Data?.needDetailSearch ? (
            <Grid.Row centered>
              <Grid.Column width={10}>
                <Segment>
                  <Header size={isDesktop ? 'large' : 'small'} dividing>
                    <Icon name={'hand point up outline'} />
                    <Header.Content>세부 모델 선택</Header.Content>
                    <Header.Subheader>
                      조회된 [{step1Data.licensePlateNumber}] 차량은 다양한 세부 모델이 있습니다.
                      <br />
                      <b>정확한 차량 시세 확인과 원활한 예약 진행을 위해</b> 세부 모델을 선택해주세요.
                    </Header.Subheader>
                  </Header>
                  {step1GuessData?.resultCode === 'OK' && (
                    <Message size={isDesktop ? 'large' : 'small'} info>
                      <Message.Content>
                        <Message.Header>
                          <Icon name='check' />
                          세부 모델 선택에 참고하세요!
                        </Message.Header>
                        카몬에서 확인한 세부 모델은 [{' '}
                        <span style={{ fontWeight: 'bold' }}>
                          {_.split(_.get(step1GuessData, 'data.carOpt'), '(')[0]}
                        </span>{' '}
                        ] 입니다.
                        {/*확실하지 않으시다면, 아래 '확실하지 않아요.'를 버튼을 {isDesktop ? '클릭' : '탭'}하세요.*/}
                      </Message.Content>
                    </Message>
                  )}
                  {_.map(_.get(step1Data.carInformation, 'modellist'), (model, modelIdx) => {
                    return (
                      <Segment key={`MD_${modelIdx}`}>
                        <Header dividing>{model.modelname}</Header>
                        <List divided relaxed>
                          {_.map(_.get(model, 'serieslist'), (series, seriesIdx) => (
                            <List.Item key={`MD_${modelIdx}_SE_${seriesIdx}`}>
                              <Checkbox
                                radio
                                label={`${series.seriesname} (출고가 ${CommonUtil.Unit.format(series.seriesprice)}원)`}
                                name='seriesNoGroup'
                                datatext={series.seriesname}
                                value={series.seriesno}
                                checked={step1Params.seriesNo === series.seriesno}
                                className={
                                  step1Params.seriesNo === series.seriesno ? 'checkbox-checked' : 'checkbox-unchecked'
                                }
                                onClick={(e, { datatext, value, checked }) => {
                                  setStep1Params((prevState) => ({
                                    ...prevState,
                                    seriesName: checked ? datatext : null,
                                    seriesNo: checked ? value : null,
                                  }));
                                }}
                              />
                            </List.Item>
                          ))}
                        </List>
                      </Segment>
                    );
                  })}
                  <Button
                    fluid
                    color={'yellow'}
                    disabled={step1Params.seriesNo === null}
                    content={'세부모델선택'}
                    icon={'check'}
                    onClick={() => getCarInformationDetail(true)}
                  />
                  {/*<Button disabled={step2Params.seriesNo !== null} content={'확실하지 않아요.'} icon={'question'}*/}
                  {/*        onClick={() => getCarInformationDetail(false)} />*/}
                </Segment>
              </Grid.Column>
            </Grid.Row>
          ) : (
            <>
              <Grid.Row centered id='car-detail-info-row'>
                <Grid.Column width={10}>
                  <Header as={'h3'} dividing>
                    <Icon name={'info circle'} />
                    <Header.Content>
                      차량상세정보
                      <Header.Subheader>
                        [{_.get(step1Data, 'carInformation.cardata.carnum')}] 차량의 정보를 알려드립니다!
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                  <Item.Group>
                    <Item>
                      <Item.Image
                        src={_.get(step1Data, 'carInformation.cardata.modelimage')}
                        style={{
                          boxShadow: '1px 1px 5px 1px rgba(0,0,0,0.2)',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      />
                      <Item.Content>
                        <Item.Header as='a'>{_.get(step1Data, 'carInformation.cardata.regname')}</Item.Header>
                        <Item.Meta>
                          {_.get(step1Data, 'carInformation.cardata.cargubun') || '정보없음'} |{' '}
                          {_.get(step1Data, 'carInformation.cardata.makername')} |{' '}
                          {_.get(step1Data, 'carInformation.cardata.modelname')}
                        </Item.Meta>
                        <Item.Description>
                          <Table basic={'very'} compact collapsing style={{ width: '100%' }}>
                            <Table.Header />
                            <Table.Body>
                              <Table.Row>
                                <Table.Cell>등급</Table.Cell>
                                <Table.Cell>
                                  {_.get(step1Data, 'carInformation.cardata.seriesname')} (
                                  {_.get(step1Data, 'carInformation.cardata.seriesname1')}{' '}
                                  {_.get(step1Data, 'carInformation.cardata.seriesname2')})
                                </Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell>연식</Table.Cell>
                                <Table.Cell>{_.get(step1Data, 'carInformation.cardata.year')}년</Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell>최초등록</Table.Cell>
                                <Table.Cell>
                                  {moment(_.get(step1Data, 'carInformation.cardata.firstdate')).format(
                                    'YYYY년 MM월 DD일'
                                  )}
                                </Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell>신차가격</Table.Cell>
                                <Table.Cell>
                                  {_.get(step1Data, 'carInformation.cardata.newprice') ? (
                                    <>{CommonUtil.Unit.format(_.get(step1Data, 'carInformation.cardata.newprice'))}원</>
                                  ) : (
                                    '정보없음'
                                  )}
                                </Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell>출고가격</Table.Cell>
                                <Table.Cell>
                                  {_.get(step1Data, 'carInformation.cardata.carmakeprice') ? (
                                    <>
                                      {CommonUtil.Unit.format(_.get(step1Data, 'carInformation.cardata.carmakeprice'))}
                                      원
                                    </>
                                  ) : (
                                    '정보없음'
                                  )}
                                </Table.Cell>
                              </Table.Row>
                            </Table.Body>
                          </Table>
                        </Item.Description>
                        <Item.Extra>
                          <Label>{_.get(step1Data, 'carInformation.cardata.gearbox') || '정보없음'}</Label>
                          <Label>{_.get(step1Data, 'carInformation.cardata.fuel') || '정보없음'}</Label>
                          <Label>{_.get(step1Data, 'carInformation.cardata.color') || '정보없음'}</Label>
                        </Item.Extra>
                      </Item.Content>
                    </Item>
                  </Item.Group>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered>
                <Grid.Column width={10}>
                  <Header as={'h3'} dividing>
                    <Icon name={'chart bar'} />
                    <Header.Content>
                      예상판매시세
                      <Header.Subheader>
                        [{_.get(step1Data, 'carInformation.cardata.carnum')}] 차량의 예상판매금액을 알려드립니다!
                      </Header.Subheader>
                    </Header.Content>
                  </Header>

                  <Grid stackable centered>
                    <Grid.Row>
                      <Grid.Column textAlign={'center'}>
                        <Header as={'h1'} style={{ lineHeight: 1.1, marginTop: 20 }}>
                          <div>예상 판매가는&nbsp;</div>
                          <div className={'price-title'}>
                            <CountUp end={_.get(step1Data, 'chartData.ccp.main.oprice') / 10000} separator={','} />
                            ~
                            <CountUp end={_.get(step1Data, 'chartData.ccp.main.cprice') / 10000} separator={','} />
                            만원
                          </div>
                          <div>입니다.</div>
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Message size={isDesktop ? 'large' : 'small'} icon={isDesktop} info floating>
                          {isDesktop && <Icon name='exclamation triangle' />}
                          <Message.Content>
                            <Message.Header>
                              {isMobile && <Icon name='exclamation triangle' />}
                              꼭! 읽어보세요, 카몬의 판매가가 높은 이유.
                            </Message.Header>
                            <p
                              style={{
                                wordWrap: 'break-word',
                                wordBreak: 'keep-all',
                              }}
                            >
                              중고차 딜러는 차량 판매자로부터 차량을 구매하기 위해서 다음과 같은 절차를 거칩니다.
                            </p>
                            <List bulleted>
                              <List.Item
                                style={{
                                  wordWrap: 'break-word',
                                  wordBreak: 'keep-all',
                                }}
                              >
                                중고차 시장에서 구매 대상 차량을 찾거나, 판매자의 연락을 받습니다.
                              </List.Item>
                              <List.Item
                                style={{
                                  wordWrap: 'break-word',
                                  wordBreak: 'keep-all',
                                }}
                              >
                                차량 판매자와 지속적인 연락을 하고, 차량을 직접 보기 위해 시간과 비용을 들입니다.
                              </List.Item>
                              <List.Item
                                style={{
                                  wordWrap: 'break-word',
                                  wordBreak: 'keep-all',
                                }}
                              >
                                차량의 상태가 생각보다 나쁘거나, 원하는 가격에 맞지 않는 등의 이유로 차량 구매에
                                실패하면, 이 때 들였던 시간과 비용이 손실이 됩니다.
                              </List.Item>
                              <List.Item
                                style={{
                                  wordWrap: 'break-word',
                                  wordBreak: 'keep-all',
                                }}
                              >
                                이러한 시간, 비용의 손실을 줄이기 위해 부당한 감가를 시도할 여지가 많아집니다.
                              </List.Item>
                            </List>
                            <p
                              style={{
                                wordWrap: 'break-word',
                                wordBreak: 'keep-all',
                              }}
                            >
                              카몬에서는 국가 공인 정비소의{' '}
                              <b>사전 성능 진단을 통해 판매자와 딜러에게 명확한 정보를 제공하여</b>
                              , 딜러에게 발생할 수 있는 시간과 비용의 손실을 줄여줍니다.
                              <br />
                              그래서, 그 <b>비용이 차량의 판매가에 반영되도록</b> 하여, 판매가를 높이고 있습니다.
                            </p>
                          </Message.Content>
                        </Message>

                        <Message color='grey'>
                          <Message.Header>
                            <Icon name='exclamation triangle' />
                            차량 진단 결과가 반영되기 전의 예상 판매가로, 실제 판매가와는 차이가 있을 수 있습니다.
                          </Message.Header>
                        </Message>
                      </Grid.Column>
                    </Grid.Row>

                    {/*<Grid.Row>
                          <Grid.Column>
                            <Header as={'h4'} attached={'top'}>
                              <Header.Content>
                                예상 판매가
                                <Header.Subheader>
                                  (차량 진단 결과가 반영되기 전의 예상 판매가로, 실제 판매가와 다소 차이가 있을 수 있습니다.)
                                </Header.Subheader>
                              </Header.Content>
                            </Header>
                            <Segment attached>
                              <Chart
                                {...(isMobile && {
                                  height: 260,
                                })}
                                type={'bar'}
                                data={priceChartData}
                                options={{
                                  indexAxis: 'y',
                                  elements: {
                                    bar: {
                                      borderWidth: 2,
                                    },
                                  },
                                  responsive: true,
                                  maintainAspectRatio: !isMobile,
                                  plugins: {
                                    legend: {
                                      position: 'bottom',
                                    },
                                    datalabels: {
                                      display: true,
                                      font: (context) => {
                                        const {
                                          dataset: {
                                            label,
                                          },
                                        } = context;

                                        if (label === '카몬판매가') {
                                          return {
                                            size: 20,
                                            weight: 'bold',
                                          };
                                        } else {
                                          return {
                                            weight: 'normal',
                                          };
                                        }
                                      },
                                      formatter: (value) => `${CommonUtil.Unit.format(value / 10000)}만원 예상`,
                                    },
                                  },
                                  scales: {
                                    x: {
                                      ticks: {
                                        callback: (value, index, ticks) => CommonUtil.Unit.format(value / (isMobile ? 10000 : 1)),
                                      },
                                      grace: 100000,
                                    },
                                  },
                                }} />
                            </Segment>
                            <Message size={isDesktop ? 'large' : 'small'} attached={'bottom'}>
                              <Message.Header>
                                꼭, 읽어보세요, 카몬의 판매가가 높은 이유.
                              </Message.Header>
                              <Message.Content>
                                <p style={{wordWrap: 'break-word', 'wordBreak': 'keep-all'}}>
                                  보통, 중고차 딜러는 차량 판매자로부터 차량을 구매하기 위해서 다음과 같은 절차를 거칩니다.
                                </p>
                                <List bulleted>
                                  <List.Item style={{wordWrap: 'break-word', 'wordBreak': 'keep-all'}}>
                                    중고차 시장에서 구매 대상 차량을 찾거나, 판매자의 연락을 받습니다.
                                  </List.Item>
                                  <List.Item style={{wordWrap: 'break-word', 'wordBreak': 'keep-all'}}>
                                    차량 판매자와 지속적인 연락을 하고, 차량을 직접 보기 위해 시간과 비용을 들입니다.
                                  </List.Item>
                                  <List.Item style={{wordWrap: 'break-word', 'wordBreak': 'keep-all'}}>
                                    차량의 상태가 생각보다 나쁘거나, 원하는 가격에 맞지 않는 등의 이유로 차량 구매에 실패하면, 이 때 들였던 시간과 비용이 손실이 됩니다.
                                  </List.Item>
                                  <List.Item style={{wordWrap: 'break-word', 'wordBreak': 'keep-all'}}>
                                    이러한 시간, 비용의 손실을 줄이기 위해 부당한 감가를 시도할 여지가 많아집니다.
                                  </List.Item>
                                </List>
                                <p style={{wordWrap: 'break-word', 'wordBreak': 'keep-all'}}>
                                  카몬에서는 국가 공인 정비소의 <b>사전 성능 진단을 통해 판매자와 딜러에게 명확한 정보를 제공하여</b>, 딜러에게 발생할 수 있는 시간과 비용의
                                  손실을
                                  줄여줍니다.<br />
                                  그래서, 그 <b>비용이 차량의 판매가에 반영되도록</b> 하여, 판매가를 높이고 있습니다.
                                </p>
                              </Message.Content>
                            </Message>

                            <Header as={'h4'} attached={'top'}>
                              <Header.Content>
                                동일 모델 실거래가
                                <Header.Subheader>
                                  최근 1년간의 자동차양도증명서 상의 신고가 기준입니다.
                                </Header.Subheader>
                              </Header.Content>
                            </Header>
                            <Segment attached={'bottom'}>
                              <Chart
                                {...(isMobile && {
                                  height: 220,
                                })}
                                type={'bar'}
                                data={c3pChartData}
                                options={{
                                  responsive: true,
                                  maintainAspectRatio: !isMobile,
                                  interaction: {
                                    mode: 'index',
                                    intersect: false,
                                  },
                                  stacked: false,
                                  plugins: {
                                    legend: {
                                      position: 'bottom',
                                    },
                                    datalabels: {
                                      display: !isMobile,
                                      backgroundColor: (context) => context.dataset.borderColor,
                                      borderRadius: 4,
                                      color: '#ffffff',
                                      formatter: (value) => CommonUtil.Unit.format(value),
                                    },
                                  },
                                  scales: {
                                    x: {
                                      title: {
                                        display: false,
                                      },
                                    },
                                    'y-count': {
                                      type: 'linear',
                                      position: 'left',
                                      title: {
                                        display: false,
                                        text: '건수',
                                      },
                                      grid: {
                                        display: true,
                                        drawOnChartArea: true,
                                      },
                                      grace: 2,
                                    },
                                    'y-amount': {
                                      type: 'linear',
                                      position: 'right',
                                      title: {
                                        display: false,
                                        text: '금액',
                                      },
                                      grid: {
                                        display: true,
                                        drawOnChartArea: true,
                                      },
                                      ticks: {
                                        callback: (value, index, ticks) => CommonUtil.Unit.format(value / (isMobile ? 10000 : 1)),
                                      },
                                      grace: 200000,
                                    },
                                  },
                                }} />
                            </Segment>

                            <Header as={'h4'} attached={'top'}>
                              <Header.Content>
                                자연 감가 예측
                                <Header.Subheader>
                                  아쉽게도, 시간이 지날수록 소중한 내 차의 가치는 떨어지기 마련입니다. <Icon name={'meh outline'} /><br />
                                  지금 바로 카몬에 예약하세요! <Icon name={'smile outline'} />
                                </Header.Subheader>
                              </Header.Content>
                            </Header>
                            <Segment attached>
                              <Chart
                                {...(isMobile && {
                                  height: 220,
                                })}
                                type={'line'}
                                data={priceReductionChartData}
                                options={{
                                  responsive: true,
                                  maintainAspectRatio: !isMobile,
                                  interaction: {
                                    mode: 'index',
                                    intersect: false,
                                  },
                                  stacked: false,
                                  plugins: {
                                    legend: {
                                      position: 'bottom',
                                    },
                                    datalabels: {
                                      display: (context) => (context.dataIndex % (!isMobile ? 10 : 20) === 0),
                                      backgroundColor: (context) => context.dataset.borderColor,
                                      borderRadius: 4,
                                      color: '#ffffff',
                                      formatter: (value) => CommonUtil.Unit.format(value),
                                    },
                                  },
                                  scales: {
                                    amount: {
                                      type: 'linear',
                                      display: true,
                                      position: 'right',
                                      ticks: {
                                        callback: (value, index, ticks) => CommonUtil.Unit.format(value / (isMobile ? 10000 : 1)),
                                      },
                                      grace: 100000,
                                    },
                                  },
                                }} />
                            </Segment>
                            <Header as={'h4'} attached>
                              <Header.Content>
                                감가율 / 잔존가치
                                <Header.Subheader>
                                </Header.Subheader>
                              </Header.Content>
                            </Header>
                            <Segment attached={'bottom'}>
                              <Chart
                                {...(isMobile && {
                                  height: 220,
                                })}
                                type={'line'}
                                data={reductionRateChartData}
                                options={{
                                  responsive: true,
                                  maintainAspectRatio: !isMobile,
                                  interaction: {
                                    mode: 'index',
                                    intersect: false,
                                  },
                                  stacked: false,
                                  plugins: {
                                    legend: {
                                      position: 'bottom',
                                    },
                                    datalabels: {
                                      display: (context) => (context.dataIndex % (!isMobile ? 10 : 20) === 0),
                                      backgroundColor: (context) => context.dataset.borderColor,
                                      borderRadius: 4,
                                      color: '#ffffff',
                                      formatter: value => value.toFixed(2),
                                    },
                                  },
                                  scales: {
                                    ratio: {
                                      type: 'linear',
                                      display: true,
                                      position: 'right',
                                      grid: {
                                        drawOnChartArea: false,
                                      },
                                    },
                                  },
                                }} />
                            </Segment>
                          </Grid.Column>
                        </Grid.Row>*/}
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </>
          ))}

        {errorData !== null && (
          <Grid.Row id='row-error-data' centered>
            <Grid.Column width={10}>
              <Message size={isDesktop ? 'large' : 'small'} negative icon={isDesktop}>
                {isDesktop && <Icon name={'meh outline'} />}
                <Message.Content>
                  <Message.Header>
                    {isMobile && <Icon name={'meh outline'} />}[{_.get(step1Params, 'licensePlateNumber')}] 차량 정보를
                    확인하지 못했습니다. 다시 한번 시도해보세요.
                  </Message.Header>
                  <p style={{ wordBreak: 'keep-all' }}>{_.join(_.get(errorData, 'details'), ' ')}</p>
                </Message.Content>
              </Message>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </ReservationStepOuter>
  );
}
