import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  List,
  Message,
  Modal,
  ModalHeader,
  Select,
  Table,
} from 'semantic-ui-react';
import { BASE_STYLES, CARMON_INFO } from '../../../../Consts';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import ReservationContext from '../../../../stores/ReservationContext';
import { CommonUtil } from '../../../../utils/CommonUtil';
import CarmonPrivacyPolicy from '../../../popup/PrivacyPolicy';
import ReservationStepOuter from '../ReservationStepOuter';
import PaymentPopupButton from '../../PaymentPopupButton';

const MY_STEP = 3;

export default function NewReservationStep3(props) {
  const {
    currentStep,
    resetStep,
    backToPrevStep,
    setToMyStep,
    goToNextStep,
    startLoading,
    finishLoading,
    buildButtons,
  } = useContext(ReservationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { params, step1Data, step2Data } = location.state || {};
  const [isProceeding, setIsProceeding] = useState(false);
  const [isMapOpened, setIsMapOpened] = useState(false);
  const [isConfirmOpened, setIsConfirmOpened] = useState(false); //TODO - 결제성공 시 모달 닫기, 새로운 성공 모달 띄우기
  const [isCompleted, setIsCompleted] = useState(false);

  const mergedParams = _.merge(
    {
      privacyPolicyAgreed: false,
      registerName: '',
      registerContact: '',
    },
    params
  );
  const [step3Params, setStep3Params] = useState(mergedParams);
  const [step3Data, setStep3Data] = useState(null);
  const [step3ParamsError, setStep3ParamsError] = useState({
    registerName: false,
    registerContact: false,
  });

  const [previousReservation, setPreviousReservation] = useState(null);
  const [errorData, setErrorData] = useState(null);

  const contactRef = useRef(null);
  const [showRegisterNameError, setShowRegisterNameError] = useState(false);
  const [showRegisterContactError, setShowRegisterContactError] = useState(false);

  let timer = null;
  const proceedReservation = () => {
    setStep3Data(null);
    setIsProceeding(true);
    timer = setTimeout(() => startLoading(), 3000);

    axios
      .post('/apis/reservation/step4', {
        carOwnerName: step3Params.carOwnerName,
        licensePlateNumber: step3Params.licensePlateNumber,
        //centerId: step2Data.center.centerId,
        registerName: step3Params.registerName || step3Params.carOwnerName,
        registerContact: step3Params.registerContact,
        reservationDate: step2Data.reservationDate,
        reservationTime: step2Data.reservationTime,
        userAddress: step2Data.userAddress,
        sido: step2Data.sido,
        sigungu: step2Data.sigungu,
        saleType: step3Params.saleType,
      })
      .then(({ data }) => {
        var _nasa = {};
        if (window.wcs) _nasa['cnv'] = window.wcs.cnv('1', '10'); // 전환유형, 전환가치 설정해야함.

        setStep3Data(data);
        setIsConfirmOpened(false);
        setIsCompleted(true);
      })
      .catch(({ response }) => {
        const { status, data } = response;
        if (status === 409) {
          setPreviousReservation(data);
        } else {
          setErrorData(data);
          setIsConfirmOpened(false);
        }
      })
      .finally(() => {
        if (timer !== null) {
          clearTimeout(timer);
          timer = null;
        }

        finishLoading();
        setIsProceeding(false);
      });
  };

  const [payMethod, setPaymethod] = useState('VACNT');
  const paymentOptions = [
    { key: '0', value: 'VACNT', text: '가상계좌' },
    { key: '1', value: 'CARD', text: '카드' },
  ];

  useEffect(() => setToMyStep(MY_STEP), []);

  useLayoutEffect(() => {
    if (isDesktop) setTimeout(() => contactRef.current.querySelector('input').focus(), 100);
  }, []);

  useEffect(() => {
    buildButtons([
      <Button
        key='btn-back-prev'
        size={isDesktop ? 'large' : 'small'}
        icon='angle left'
        content='이전'
        disabled={isProceeding}
        onClick={() => {
          backToPrevStep({
            params,
            step1Data,
            step2Data,
          });
        }}
      />,
      <Button
        size={isDesktop ? 'large' : 'small'}
        key='btn-go-next'
        loading={isProceeding}
        color={'yellow'}
        icon='calendar check'
        disabled={!CommonUtil.Validator.validateCellPhone(step3Params.registerContact)}
        content='예약접수'
        onClick={() => {
          const isRegisterNameInvalid = step3Params.registerName !== '' && step3ParamsError.registerName;
          const isRegisterContactInvalid = step3Params.registerContact === '' || step3ParamsError.registerContact;

          if (step3Data !== null || isRegisterNameInvalid || isRegisterContactInvalid) {
            setShowRegisterNameError(isRegisterNameInvalid);
            setShowRegisterContactError(isRegisterContactInvalid);
            return false;
          }

          setIsConfirmOpened(true);
        }}
      />,
    ]);
  }, [step3Params, step3ParamsError, isProceeding]);

  //ANCHOR - 결제정보 모달
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState(null);

  return (
    <ReservationStepOuter myStep={MY_STEP} isLoading={isProceeding}>
      <Header as={'h2'}>
        <Header.Content>
          예약하시는 분은 누구신가요?
          <Header.Subheader>
            카몬의 서비스는 카카오 알림톡을 통해 진행됩니다. 정확한 휴대전화번호를 입력해주세요!
          </Header.Subheader>
        </Header.Content>
      </Header>

      <Header as='h3' dividing>
        <Icon name={'info circle'} />
        <Header.Content>
          <div className='text-blue-500'>예약내용</div>
          <Header.Subheader>
            <div className='text-sm'>이렇게 예약하셨습니다. 다시 한번 확인해보세요!</div>
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Grid stackable>
        <Grid.Row columns={'equal'}>
          <Grid.Column>
            <Table size='large' compact stackable>
              <Table.Header />
              <Table.Body>
                <Table.Row>
                  <Table.Cell width={3} style={{ fontWeight: 'bold' }} verticalAlign={'top'}>
                    1. 차량정보
                  </Table.Cell>
                  <Table.Cell width={10}>
                    {_.get(step1Data, 'carInformation.cardata.carnum')} (소유자 {_.get(step1Data, 'carOwnerName')})
                    <br />
                    {_.get(step1Data, 'carInformation.cardata.regname')}
                  </Table.Cell>
                  <Table.Cell width={3} verticalAlign={'top'}></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={3} style={{ fontWeight: 'bold' }} verticalAlign={'top'}>
                    2. 희망방문일시
                  </Table.Cell>
                  <Table.Cell width={10}>
                    {moment(_.get(step2Data, 'reservationDate')).format('YYYY년 MM월 DD일')},{' '}
                    {_.get(step2Data, 'reservationTime')}
                  </Table.Cell>
                  <Table.Cell width={3} verticalAlign={'top'}></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={3} style={{ fontWeight: 'bold' }} verticalAlign={'top'}>
                    3. 주소
                  </Table.Cell>
                  <Table.Cell width={10}>
                    {_.get(step2Data, 'userAddress')}
                    <br />
                    <span style={{ color: '#999', fontSize: '.8rem' }}>
                      해당 주소지를 기준으로 가장 가까운 정비소를 안내드립니다.
                    </span>
                  </Table.Cell>
                  <Table.Cell width={3} verticalAlign={'top'}></Table.Cell>
                </Table.Row>
                {/*<Table.Row>*/}
                {/*  <Table.Cell width={3} style={{fontWeight: 'bold'}} verticalAlign={'top'}>3. 방문정비소</Table.Cell>*/}
                {/*  <Table.Cell width={10}>*/}
                {/*    {_.get(step2Data, 'center.centerName')}<br />*/}
                {/*    ({_.get(step2Data, 'center.address')})*/}
                {/*  </Table.Cell>*/}
                {/*  <Table.Cell width={3} verticalAlign={'middle'} textAlign={'right'}>*/}
                {/*    <Button icon="map" content="지도보기"*/}
                {/*            onClick={() => setIsMapOpened(true)} />*/}
                {/*  </Table.Cell>*/}
                {/*</Table.Row>*/}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header as='h3' dividing>
        <Icon name={'user circle'} />
        <Header.Content>
          <div className='text-blue-500'>예약자 정보</div>
          <Header.Subheader>
            <div className='text-sm'>이제 다 왔습니다!</div>
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Grid stackable>
        <Grid.Row width={'equal'}>
          <Grid.Column>
            <Form size={'large'}>
              <Form.Group widths='equal'>
                <Form.Field
                  control={Input}
                  label={'예약자 - 소유자와 다를 경우에만 입력하세요.'}
                  placeholder={`${_.get(step1Data, 'carOwnerName')}`}
                  maxLength={13}
                  style={{ fontSize: BASE_STYLES.FONT_SIZE.SUB }}
                  value={step3Params.registerName}
                  onChange={(e, { value }) => {
                    setStep3ParamsError((prevState) => ({
                      ...prevState,
                      registerName: false,
                    }));
                    setShowRegisterNameError(false);
                    setShowRegisterContactError(false);

                    const trimmed = value.replace(/\s/g, '');
                    const regExp = /^[ㄱ-ㅎㅏ-ㅣ가-힣ㆍᆢA-Za-z]+$/;
                    setStep3ParamsError((prevState) => ({
                      ...prevState,
                      registerName: trimmed === '' ? false : !regExp.test(trimmed),
                    }));
                    setStep3Params((prevState) => ({
                      ...prevState,
                      registerName: trimmed,
                    }));
                  }}
                  icon={
                    step3Params.registerName !== '' && (
                      <Icon
                        name='remove'
                        link
                        onClick={() => {
                          setStep3Params((prevState) => ({
                            ...prevState,
                            registerName: '',
                          }));
                          setStep3ParamsError((prevState) => ({
                            ...prevState,
                            registerName: false,
                          }));
                        }}
                      />
                    )
                  }
                  error={
                    showRegisterNameError
                      ? step3Params.registerName !== '' && step3ParamsError.registerName
                        ? {
                            content: '올바른 예약자명을 입력하세요.',
                            pointing: 'above',
                          }
                        : false
                      : step3Params.registerName !== '' && step3ParamsError.registerName
                  }
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && step3Params.registerName !== '' && !step3ParamsError.registerName) {
                      e.target.blur();
                      contactRef.current.querySelector('input').focus();
                    }
                  }}
                />
                <div ref={contactRef}>
                  <Form.Field
                    control={Input}
                    label={'휴대전화'}
                    placeholder='휴대전화'
                    maxLength={13}
                    style={{ fontSize: BASE_STYLES.FONT_SIZE.SUB }}
                    value={step3Params.registerContact}
                    onChange={(e, { value }) => {
                      setShowRegisterNameError(false);
                      setShowRegisterContactError(false);

                      const refined = CommonUtil.CellPhone.format(value);
                      setStep3ParamsError((prevState) => ({
                        ...prevState,
                        registerContact: !CommonUtil.Validator.validateCellPhone(refined),
                      }));
                      setStep3Params((prevState) => ({
                        ...prevState,
                        registerContact: refined,
                      }));
                    }}
                    icon={
                      step3Params.registerContact !== '' && (
                        <Icon
                          name='remove'
                          link
                          onClick={() =>
                            setStep3Params((prevState) => ({
                              ...prevState,
                              registerContact: '',
                            }))
                          }
                        />
                      )
                    }
                    error={
                      showRegisterContactError
                        ? step3Params.registerContact !== '' && step3ParamsError.registerContact
                          ? {
                              content: '올바른 휴대전화번호를 입력하세요.',
                              pointing: 'above',
                            }
                          : false
                        : step3Params.registerContact !== '' && step3ParamsError.registerContact
                    }
                    onKeyPress={(e) => {
                      if (
                        e.key === 'Enter' &&
                        step3Params.registerContact !== '' &&
                        !step3ParamsError.registerContact
                      ) {
                        e.target.blur();
                        setIsConfirmOpened(true);
                      }
                    }}
                  />
                </div>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {step3Params.saleType === 'P' && (
        <>
          <Header as='h3' dividing>
            <Icon className='won' />
            <Header.Content>
              <div className='text-blue-500'>결제내용</div>
              <Header.Subheader>
                <div className='text-sm'>결제금액을 확인해주세요!</div>
              </Header.Subheader>
            </Header.Content>
          </Header>
          <Grid stackable>
            <Grid.Row columns='equal'>
              <Grid.Column>
                <Table size='large' compact stackable>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width={3}>상품명</Table.Cell>
                      <Table.Cell width={10}>
                        점검료 (
                        {step1Data.carInformation.cardata.cho === '1'
                          ? '국산'
                          : step1Data.carInformation.cardata.cho === '2'
                          ? '수입'
                          : ''}
                        &nbsp;승용차)
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={3}>결제금액</Table.Cell>
                      <Table.Cell width={10}>{Number(step1Data.checkPrice).toLocaleString()}원</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={3}>결제수단</Table.Cell>
                      <Table.Cell width={10}>
                        <Select
                          placeholder='결제수단'
                          options={paymentOptions}
                          value={payMethod}
                          onChange={(e, { value }) => setPaymethod(value)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      )}
      <Grid stackable>
        {previousReservation !== null && (
          <Grid.Row>
            <Grid.Column>
              <Message negative size={isDesktop ? 'large' : 'small'} icon={isDesktop}>
                {isDesktop && <Icon name={'calendar alternate outline'} />}
                <Message.Content>
                  <Message.Header>
                    {isMobile && <Icon name={'calendar alternate outline'} />}
                    이미 등록된 예약이 있습니다!
                  </Message.Header>
                  예약을 잊으셨나요? 카카오 알림톡 <b>[카몬 채널]</b>을 확인하세요!{' '}
                  <Icon name={'smile outline'} fitted />
                  <br />
                  새로운 예약을 등록하시려면 해당 예약을 취소하고 새로운 예약을 접수해주세요.
                  <Table basic compact stackable>
                    <Table.Header />
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width={3} style={{ fontWeight: 'bold' }} verticalAlign={'top'}>
                          예약번호
                        </Table.Cell>
                        <Table.Cell width={13}>{_.get(previousReservation, 'reservationNumber')}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={3} style={{ fontWeight: 'bold' }} verticalAlign={'top'}>
                          예약일시
                        </Table.Cell>
                        <Table.Cell width={13}>
                          {moment(_.get(previousReservation, 'reservationDate')).format('YYYY년 MM월 DD일')},{' '}
                          {_.get(previousReservation, 'reservationTime')}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={3} style={{ fontWeight: 'bold' }} verticalAlign={'top'}>
                          예약자
                        </Table.Cell>
                        <Table.Cell width={13}>
                          {_.get(previousReservation, 'registerName')} ({_.get(previousReservation, 'registerContact')})
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={3} style={{ fontWeight: 'bold' }} verticalAlign={'top'}>
                          방문정비소
                        </Table.Cell>
                        <Table.Cell width={13}>
                          {_.has(previousReservation, 'center.name')
                            ? `
                                ${_.get(previousReservation, 'center.name')} <br />
                                (${_.get(previousReservation, 'center.address')} ${_.get(
                                previousReservation,
                                'center.addressDetail'
                              )})  
                              `
                            : '카몬이 최적의 정비소를 확인중입니다.'}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={3} style={{ fontWeight: 'bold' }} verticalAlign={'top'}>
                          등록일시
                        </Table.Cell>
                        <Table.Cell width={13}>
                          {moment(_.get(previousReservation, 'registerTime')).format('YYYY-MM-DD HH:mm:ss')}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Message.Content>
              </Message>
            </Grid.Column>
          </Grid.Row>
        )}
        {errorData !== null && (
          <Grid.Row>
            <Grid.Column>
              <Message negative icon>
                <Icon name={'meh'} />
                <Message.Content>
                  <Message.Header>예약을 등록할 수 없습니다.</Message.Header>
                  <p>{_.join(_.get(errorData, 'details'), ' ')}</p>
                </Message.Content>
              </Message>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Grid.Column>
            <Message size='large' warning icon={isDesktop}>
              {isDesktop && <Icon name={'exclamation triangle'} />}
              <Message.Content>
                <Message.Header>
                  {isMobile && <Icon name={'exclamation triangle'} />}예약하시기 전에 꼭 확인해주세요!
                </Message.Header>
                <List bulleted>
                  <List.Item>
                    카몬은 <b>카카오 알림톡</b>을 통해 내 차 팔기가 진행됩니다.
                  </List.Item>
                  <List.Item>
                    알림톡을 수신할 수 있는 <b>휴대전화번호를 정확히 입력해주세요.</b>
                  </List.Item>
                  <List.Item>예약이 등록되면 예약접수 알림톡을 받아보실 수 있습니다.</List.Item>
                  <List.Item>
                    <b>
                      예약 접수 후 알림톡을 10분 이내에 수신하지 못하신 경우 고객센터로 연락하시거나, 예약을 새로 접수해
                      주시기 바랍니다.
                      <br />
                      (카몬고객센터 : {CARMON_INFO.SUPPORT_TEL}, 운영시간 : {CARMON_INFO.SUPPORT_TIME})
                    </b>
                  </List.Item>
                  <List.Item>
                    영업일 기준 1~2일 이내에 입력하신 주소를 기준으로 카몬이 가장 가까운 정비소를 지정하여 [예약 확정]
                    안내 알림톡을 보내드립니다.
                  </List.Item>
                </List>
              </Message.Content>
            </Message>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {/*<Modal*/}
      {/*  onClose={() => setIsMapOpened(false)}*/}
      {/*  onOpen={() => setIsMapOpened(true)}*/}
      {/*  open={isMapOpened}>*/}
      {/*  <Modal.Header>*/}
      {/*    정비소 위치 보기*/}
      {/*  </Modal.Header>*/}
      {/*  <Modal.Content>*/}
      {/*    <KakaoMap*/}
      {/*      mapId={'reservation-step3-map'}*/}
      {/*      latitude={step2Data.center.latitude}*/}
      {/*      longitude={step2Data.center.longitude}*/}
      {/*      mapStyle={{width: '100%', height: 400}}*/}
      {/*      draggable={!isMobile} />*/}
      {/*  </Modal.Content>*/}
      {/*  <Modal.Actions>*/}
      {/*    <Button icon="close" content="닫기" onClick={() => setIsMapOpened(false)} />*/}
      {/*  </Modal.Actions>*/}
      {/*</Modal>*/}

      <Modal
        onClose={() => setIsConfirmOpened(false)}
        onOpen={() => setIsConfirmOpened(true)}
        open={isConfirmOpened}
        size='small'
      >
        <Header icon>
          <Icon name='calendar alternate' />
          <Header.Content style={{ wordBreak: 'keep-all' }}>
            {isMobile && (
              <>
                고객님의 차량진단예약 내역은
                <br />
                다음과 같습니다.
              </>
            )}
            {isDesktop && <>고객님의 차량진단예약 내역은 다음과 같습니다.</>}
            <Header.Subheader>예약을 접수하시기 전에 꼼꼼히 확인하세요!</Header.Subheader>
          </Header.Content>
        </Header>
        <Modal.Content>
          <Table inverted compact stackable>
            <Table.Header />
            <Table.Body>
              <Table.Row>
                <Table.Cell style={{ fontWeight: 'bold' }}>희망방문일시</Table.Cell>
                <Table.Cell style={{ fontWeight: 'bold' }}>
                  {moment(_.get(step2Data, 'reservationDate')).format('YYYY년 MM월 DD일')},{' '}
                  {_.get(step2Data, 'reservationTime')}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell style={{ fontWeight: 'bold' }}>예약자</Table.Cell>
                <Table.Cell style={{ fontWeight: 'bold' }}>
                  {step3Params.registerName || _.get(step1Data, 'carOwnerName')}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell style={{ fontWeight: 'bold' }}>휴대전화</Table.Cell>
                <Table.Cell style={{ fontWeight: 'bold' }}>{step3Params.registerContact}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell style={{ fontWeight: 'bold' }}>정비소</Table.Cell>
                <Table.Cell style={{ fontWeight: 'bold' }}>
                  {/*{_.get(step2Data, 'center.centerName')}<br />*/}
                  {/*({_.get(step2Data, 'center.address')})*/}
                  카몬이 최적의 정비소를 확인 후 별도 안내드립니다.
                </Table.Cell>
              </Table.Row>
              {step3Params.saleType === 'P' && (
                <>
                  <Table.Row>
                    <Table.Cell style={{ fontWeight: 'bold' }}>성능점검료</Table.Cell>
                    <Table.Cell style={{ fontWeight: 'bold' }}>
                      {Number(step1Data.checkPrice).toLocaleString()}원
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ fontWeight: 'bold' }}>성능점검보증보험료</Table.Cell>
                    <Table.Cell style={{ fontWeight: 'bold' }}>0원</Table.Cell>
                  </Table.Row>
                </>
              )}
            </Table.Body>
          </Table>
        </Modal.Content>

        {/* //ANCHOR - 예약접수 내용 */}
        <Modal.Actions>
          <Header as={'h3'}>
            <Header.Content className='mb-3'>예약을 접수하시겠습니까?</Header.Content>
            <Header.Subheader className='mb-2' style={{ wordBreak: 'keep-all', lineHeight: 1.3 }}>
              휴대전화번호를 정확히 입력하셨는지 꼭 확인하세요!
              <br />
              (예약을 접수하시면, 카몬의 개인정보취급방침에 따라 고객님의 개인정보가 관리됩니다.)
            </Header.Subheader>
          </Header>
          <CarmonPrivacyPolicy linkTitle='약관보기' style={{ float: 'left' }} />
          <Button
            icon={'remove'}
            content={'아니오'}
            onClick={() => {
              setIsConfirmOpened(false);
            }}
          />
          {/* //ANCHOR -  */}
          {step3Params.saleType === 'P' ? (
            <PaymentPopupButton
              payMethod={payMethod}
              goodsNm={
                step1Data.carInformation.cardata.cho === '1'
                  ? '국산 승용차 성능점검료'
                  : step1Data.carInformation.cardata.cho === '2'
                  ? '수입 승용차 성능점검료'
                  : ''
              }
              goodsAmt={step1Data.checkPrice}
              ordNm={step3Params.registerName || step3Params.carOwnerName}
              ordTel={step3Params.registerContact}
              setIsPaymentModalOpen={setIsPaymentModalOpen}
              setPaymentInfo={setPaymentInfo}
              setIsConfirmOpened={setIsConfirmOpened}
            />
          ) : (
            <Button
              icon={'check'}
              color={'yellow'}
              content={'예'}
              onClick={() => {
                proceedReservation();
              }}
            />
          )}
        </Modal.Actions>
      </Modal>
      {isPaymentModalOpen && (
        <Modal
          onClose={() => setIsPaymentModalOpen(false)}
          onOpen={() => setIsPaymentModalOpen(true)}
          open={isPaymentModalOpen}
          size='small'
        >
          {paymentInfo?.status === 'success' ? (
            <>
              <Modal.Header>결제성공</Modal.Header>
              <Modal.Content>
                <Header.Subheader style={{ wordBreak: 'keep-all', lineHeight: 1.3, marginBottom: '10px' }}>
                  아래와 같이 결제가 완료되었습니다.
                </Header.Subheader>
                <Modal.Description>
                  <p>- 주문번호: {paymentInfo?.ordNo}</p>
                  <p>- 결제금액: {paymentInfo?.amount}원</p>
                  <p>
                    - 결제방법:{' '}
                    {paymentInfo?.payMethod === 'VACNT'
                      ? '가상계좌'
                      : paymentInfo?.payMethod === 'CARD'
                      ? '신용카드'
                      : paymentInfo?.payMethod}
                  </p>
                  {paymentInfo?.payMethod === 'VACNT' && (
                    <>
                      <p>- 은행명: {paymentInfo?.bankName}</p>
                      <p>- 가상계좌번호: {paymentInfo?.vacntNo}</p>
                    </>
                  )}
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  icon={'check'}
                  content='확인'
                  onClick={() => {
                    setIsPaymentModalOpen(false);
                    goToNextStep({
                      params,
                      step1Data,
                      step2Data,
                      step3Params,
                      paymentInfo,
                    });
                  }}
                />
              </Modal.Actions>
            </>
          ) : (
            <>
              <Modal.Header>결제실패</Modal.Header>
              <Header.Content>
                <div>결제가 실패되었습니다. 다시 시도해주세요.</div>
              </Header.Content>
              <Modal.Actions>
                <Button content={'확인'} onClick={() => setIsPaymentModalOpen(false)} />
              </Modal.Actions>
            </>
          )}
        </Modal>
      )}
      {step3Data !== null && (
        <Modal
          size='tiny'
          onClose={() => {
            setIsCompleted(false);
            navigate('/', { state: null, replace: true });
          }}
          onOpen={() => setIsCompleted(true)}
          open={isCompleted}
        >
          <Modal.Header>
            <Icon name='smile' />
            카몬을 이용해주셔서 감사합니다!
          </Modal.Header>
          <Modal.Content>
            <Header as={isDesktop ? 'h2' : 'h3'}>
              <Header.Content>예약번호는 [{step3Data.reservationNumber}] 입니다.</Header.Content>
              <Header.Subheader style={{ paddingTop: 8, lineHeight: 1.4 }}>
                {/*<b>[{moment(step3Data.reservationDate).format('YYYY년 MM월 DD일')}, {step3Data.reservationTime} - {step3Data.center.name} 정비소]</b>에*/}
                {/*꼭 방문해주세요!<br />*/}
                고객님 주변의 예약가능 정비소가 확인되는대로 다시 안내드리겠습니다.
                <br />
                내차팔기 진행상황은 카카오톡 [<b>CARMON</b>] 채널에서 확인하실 수 있습니다.
                <br />
                카몬을 이용해주셔서 감사합니다. 보다 나은 서비스를 위해 노력하겠습니다.
              </Header.Subheader>
            </Header>
          </Modal.Content>
          <Modal.Actions>
            <Button
              icon='close'
              content='닫기'
              onClick={() => {
                setIsCompleted(false);
                navigate('/', { state: null, replace: true });
              }}
            />
          </Modal.Actions>
        </Modal>
      )}
    </ReservationStepOuter>
  );
}
