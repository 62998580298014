import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Container, Dimmer, Header, Icon, Image, Modal, Segment, Transition } from 'semantic-ui-react';

import _ from 'lodash';
import ReservationContext from '../../stores/ReservationContext';
import NewReservationStep1 from '../component/Reservation/NewSteps/NewReservationStep1';
import NewReservationStep2 from '../component/Reservation/NewSteps/NewReservationStep2';
import NewReservationStep3 from '../component/Reservation/NewSteps/NewReservationStep3';

// import ReservationStep1 from '../component/Reservation/Steps/ReservationStep1';
// import ReservationStep2 from '../component/Reservation/Steps/ReservationStep2';
// import ReservationStep3 from '../component/Reservation/Steps/ReservationStep3';
// import ReservationStep4 from '../component/Reservation/Steps/ReservationStep4';
import ReservationStep5 from '../component/Reservation/Steps/ReservationStep5';

// const STEPS = [
//   {title: '차량확인', detail: '차량번호가 어떻게 되시나요?'},
//   {title: '정보조회', detail: '공공데이터를 통해 상세한 차량정보를 알려드릴게요!'},
//   {title: '예약등록', detail: '내 집 앞 정비소에서 차량진단 받고, 진짜 내 차 판매가를 알아보세요!'},
//   {title: '예약자등록', detail: '예약자 정보를 등록합니다.'},
//   {title: '예약완료', detail: '예약이 완료되었습니다!'},
// ];

const STEPS = [
  {
    title: '차량정보 조회',
    detail: '공공데이터를 통해 상세한 차량정보를 알려드릴게요!',
  },
  {
    title: '방문 희망일시 선택',
    detail: '내 집 앞 정비소에서 차량진단 받고, 진짜 내 차 판매가를 알아보세요!',
  },
  { title: '예약자 정보 등록', detail: '예약정보를 등록합니다.' },
  { title: '예약완료', detail: '예약이 완료되었습니다!' },
];

export default function Reservation() {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const headerStickyRef = useRef();

  const [currentStep, setCurrentStep] = useState(1);
  const [isCancelOpened, setIsCancelOpened] = useState(false);

  let timer = null;
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [elapsedTime, setElapsedTime] = useState(0);

  const [actionButtons, setActionButtons] = useState([]);

  const startLoading = (message) => {
    timer = setInterval(() => setElapsedTime((prevState) => prevState + 1), 1000);
    setIsLoading(true);
    setLoadingMessage(message);
  };

  const finishLoading = () => {
    clearInterval(timer);
    timer = null;

    setIsLoading(false);
    setLoadingMessage('');
    setElapsedTime(0);
  };

  const resetStep = () => setCurrentStep(1);
  const setToMyStep = (myStep) => setCurrentStep(myStep);

  const backToPrevStep = (state) => {
    if (currentStep > 1) {
      const backStep = currentStep - 1;
      setCurrentStep(backStep);
      navigate(`../step${backStep}`, { state, replace: true });
    }
  };

  const goToNextStep = (state) => {
    if (currentStep < 5) {
      const nextStep = currentStep + 1;
      setCurrentStep(nextStep);
      navigate(`../step${nextStep}`, { state, replace: true });
    }
  };

  const buildButtons = (buttons) => {
    setActionButtons(buttons);
  };

  useEffect(() => {
    // console.log(currentStep);
    setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: 'auto' }), 100);

    if (state === null) {
      resetStep();
      navigate('../step1', { state: null, replace: true });
      return;
    }

    if (currentStep > 0 && _.has(state, `step${currentStep - 1}Data`)) {
      navigate(`../step${currentStep}`, { state, replace: true });
    }
  }, [currentStep]);

  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);

  useLayoutEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    setHeaderHeight(document.querySelector('#res-header').clientHeight);
    setFooterHeight(document.querySelector('#res-footer').clientHeight);
  }, []);

  useEffect(() => {
    const setRealVH = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', setRealVH);
    return () => window.removeEventListener('resize', setRealVH);
  });

  const [showDeliveryBanner, setShowDeliveryBanner] = useState(false);
  const [deliveryPopupOpen, setDeliveryPopupOpen] = useState(false);

  const deliveryPopupClose = () => {
    setDeliveryPopupOpen(false);
  };
  useEffect(() => {
    setTimeout(() => {
      setShowDeliveryBanner(true);
    }, 500);
  }, []);

  return (
    <Container>
      {/*<Transition visible={showDeliveryBanner} animation={'slide down'} duration={300}>*/}
      {/*  <Segment basic inverted fluid color={'orange'}*/}
      {/*           style={{*/}
      {/*             display: showDeliveryBanner ? 'block' : 'none'*/}
      {/*           }}>*/}
      {/*    <div style={{fontSize:'1.2em'}}>*/}
      {/*      <p>*/}
      {/*        정비소 방문이 힘들다면? <span style={{color:'#000',fontSize:'1.3em', display:'inline-block', textDecoration:'underline'}}>카몬 딜리버리</span>를 이용해보세요!*/}
      {/*      </p>*/}

      {/*      <Button basic inverted*/}
      {/*              floated={'right'}*/}
      {/*              icon={'times'}*/}
      {/*              content={'닫기'}*/}
      {/*              size={'small'}*/}
      {/*              color={'yellow'}*/}
      {/*              onClick={() => {*/}
      {/*                setShowDeliveryBanner(false);*/}
      {/*              }}*/}
      {/*      />*/}
      {/*      <Button basic inverted*/}
      {/*              floated={'right'}*/}
      {/*              icon={'external alternate'}*/}
      {/*              content={'카몬딜리버리 예약하기'}*/}
      {/*              size={'small'}*/}
      {/*              color={'yellow'}*/}
      {/*              onClick={() => setDeliveryPopupOpen(true) }*/}
      {/*      />*/}
      {/*      <div style={{clear:'both'}} />*/}
      {/*    </div>*/}
      {/*  </Segment>*/}
      {/*</Transition>*/}
      {/*<CarmonDelivery active={deliveryPopupOpen} popupClose={deliveryPopupClose}/>*/}

      <Segment
        id='res-header'
        basic
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 100,
          margin: 0,
          backgroundColor: '#ffffff',
          borderBottom: '2px solid #fbbd08',
        }}
      >
        <Header color={'yellow'}>
          <Header.Content
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 8,
            }}
          >
            <Header as={'h3'} color={'yellow'} style={{ flex: 1, margin: 0 }}>
              <Header.Content>
                {/*Step{currentStep} / {STEPS.length} - {STEPS[currentStep - 1].title}*/}
                Step{currentStep}. {STEPS[currentStep - 1].title}
                <Header.Subheader>{STEPS[currentStep - 1].detail}</Header.Subheader>
              </Header.Content>
            </Header>
            {currentStep < 4 ? (
              <Modal
                size={'tiny'}
                open={isCancelOpened}
                onClose={() => setIsCancelOpened(false)}
                onOpen={() => setIsCancelOpened(true)}
                trigger={<Button basic circular icon={'remove'} />}
              >
                <Modal.Header>예약진행취소</Modal.Header>
                <Modal.Content>
                  <Modal.Description>
                    <p>
                      진행중인 예약 내용이 사라집니다.
                      <br />
                      예약 등록을 취소하시겠습니까?
                    </p>
                  </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    content={'아니오'}
                    onClick={() => {
                      setIsCancelOpened(false);
                    }}
                  />
                  <Button
                    content={'예'}
                    onClick={() => {
                      setIsCancelOpened(false);
                      navigate('/', { state: null, replace: true });
                    }}
                  />
                </Modal.Actions>
              </Modal>
            ) : (
              <Button basic circular icon={'remove'} onClick={() => navigate('/', { state: null, replace: true })} />
            )}
          </Header.Content>
        </Header>
      </Segment>

      <Dimmer.Dimmable dimmed={isLoading}>
        <Segment
          basic
          style={{
            minHeight: `calc((var(--vh, 1vh) * 100) - (${headerHeight}px + ${footerHeight}px + 40px))`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingLeft: 6,
            paddingRight: 6,
          }}
        >
          <div ref={headerStickyRef}>
            <React.Fragment>
              <Segment basic style={{ flex: 1, marginTop: 12, marginBottom: 12, padding: 0 }}>
                <ReservationContext.Provider
                  value={{
                    allSteps: STEPS,
                    currentStep,
                    resetStep,
                    backToPrevStep,
                    setToMyStep,
                    goToNextStep,
                    startLoading,
                    finishLoading,
                    buildButtons,
                  }}
                >
                  {/*{pathname === '/reservation/step1' && <ReservationStep1 />}*/}
                  {/*{pathname === '/reservation/step2' && <ReservationStep2 />}*/}
                  {/*{pathname === '/reservation/step3' && <ReservationStep3 />}*/}
                  {/*{pathname === '/reservation/step4' && <ReservationStep4 />}*/}
                  {/*{pathname === '/reservation/step5' && <ReservationStep5 />}*/}

                  {pathname === '/reservation/step1' && <NewReservationStep1 />}
                  {pathname === '/reservation/step2' && <NewReservationStep2 />}
                  {pathname === '/reservation/step3' && <NewReservationStep3 />}
                  {pathname === '/reservation/step4' && <ReservationStep5 />}
                </ReservationContext.Provider>
              </Segment>
            </React.Fragment>
          </div>
        </Segment>

        <Transition visible={isLoading} animation='fade' duration={500}>
          <Dimmer active={isLoading} style={{ zIndex: 799 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 16,
              }}
            >
              <Image
                src={`/images/icons/faces/f${(_.toNumber((elapsedTime / 10).toFixed(0)) % 5) + 1}.png`}
                style={{ height: '16vmin', maxHeight: 80, filter: 'invert(1)' }}
              />
              <Header as='h2' inverted style={{ margin: 0 }}>
                {loadingMessage || (
                  <>
                    현재 요청을 처리하고 있습니다.
                    <br />
                    잠시만 더 기다려 주세요.
                  </>
                )}
                <Header.Subheader>
                  {_.times((elapsedTime % 10) + 1, (idx) => (
                    <Icon key={`DOT_${idx}`} name={'circle'} />
                  ))}
                </Header.Subheader>
              </Header>
            </div>
          </Dimmer>
        </Transition>
      </Dimmer.Dimmable>

      <Segment
        id='res-footer'
        basic
        textAlign='right'
        style={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: '#ffffff',
          borderTop: '2px solid #fbbd08',
          margin: 0,
          zIndex: 100,
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        {actionButtons}
      </Segment>
    </Container>
  );
}
