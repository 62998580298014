import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {Button, Grid, Header, Icon, Input, Message, Modal, Segment, Table} from 'semantic-ui-react';
import ReservationStepOuter from '../ReservationStepOuter';
import ReservationContext from '../../../../stores/ReservationContext';
import {useLocation} from 'react-router-dom';
import _ from 'lodash';

import KakaoMap from '../../KakaoMap';

import {DateInput} from 'semantic-ui-calendar-react-yz';
import 'moment/locale/ko';
import axios from 'axios';
import DaumPostcode from 'react-daum-postcode';
import moment from 'moment';
import {CommonUtil} from '../../../../utils/CommonUtil';
import {usePrevious} from '../../../../hooks/usePrevious';
import {BASE_STYLES} from '../../../../Consts';
import {isMobile} from 'react-device-detect';

const MY_STEP = 3;
const DATE_FORMAT = 'YYYY-MM-DD';
const START_DAY = moment().add(1, 'days');
const MAX_DAY = START_DAY.clone().add(15, 'days');
const HOLIDAYS = getHolidays(START_DAY, MAX_DAY);

function getHolidays(sDate, eDate) {
  const startDate = sDate.clone();
  const endDate = eDate.clone();
  let holidays = [];

  while (startDate.isSameOrBefore(endDate)) {
    if (startDate.weekday() === 0) holidays.push(startDate.format(DATE_FORMAT));
    startDate.add(1, 'days');
  }

  return holidays;
}

export default function ReservationStep3() {
  const {currentStep, resetStep, backToPrevStep, setToMyStep, goToNextStep} = useContext(ReservationContext);
  const location = useLocation();
  const {params, step1Data, step2Data} = location.state || {};

  const [toNext, setToNext] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPostCodeOpened, setIsPostCodeOpened] = useState(false);

  const [centers, setCenters] = useState(null);

  const [isCenterIdleTimesLoading, setIsCenterIdleTimesLoading] = useState(false);
  const [centerIdleTimes, setCenterIdleTimes] = useState([]);

  const [step3Data, setStep3Data] = useState({
    userAddress: '',
    center: null,
    reservationDate: START_DAY.format(DATE_FORMAT),
    reservationTime: '',
  });
  const prevStep3Data = usePrevious(step3Data);

  const getCenter = (userAddress) => {
    axios.get(`/apis/center?address=${userAddress || ''}`)
      .then(({data}) => {
        setCenters(data);
      })
      .catch(response => {
        console.error(response);
      });
  };

  useEffect(() => {
    if (step3Data.center === null || step3Data.center?.centerId === 0) return;
    if (prevStep3Data?.center?.centerId !== step3Data.center?.centerId
      || prevStep3Data?.reservationDate !== step3Data.reservationDate) {
      setStep3Data(prevState => ({...prevState, reservationTime: ''}));
      setIsCenterIdleTimesLoading(true);

      axios.get(`/apis/center/${step3Data?.center?.centerId}?reservationDate=${step3Data.reservationDate}`)
        .then(({data}) => {
          setCenterIdleTimes(data);
        })
        .catch(response => {
          console.error(response);
        })
        .finally(() => setIsCenterIdleTimesLoading(false));
    }
  }, [step3Data]);

  useEffect(() => {
    setToMyStep(MY_STEP);
  }, []);

  return (
    <ReservationStepOuter myStep={MY_STEP} isLoading={isLoading}>
      <Header as={'h3'}>
        <Header.Content>
          가까운 정비소를 찾아서 방문하세요!
          <Header.Subheader>
            제 값 받고 "내 차 팔기"는 여기서부터 시작됩니다.
          </Header.Subheader>
        </Header.Content>
      </Header>

      <Header as="h3" dividing>
        <Icon name={'hand point up outline'} />
        <Header.Content>
          정비소 선택
          <Header.Subheader>
            먼저 내 주소를 입력하면, 내 집 앞 정비소를 알려드립니다!
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column width={10}>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={11}>
                  <Input
                    style={{flex: 1, fontSize: BASE_STYLES.FONT_SIZE.SUB}}
                    fluid
                    value={step3Data.userAddress}
                    onChange={(e, {value}) => {
                      setStep3Data(prevState => ({...prevState, userAddress: value}));
                    }}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        e.target.blur();
                        setIsPostCodeOpened(true);
                      }
                    }}
                    icon={step3Data.userAddress !== '' &&
                    <Icon name="remove" link onClick={() => setStep3Data(prevState => ({
                      ...prevState,
                      userAddress: '',
                    }))} />}
                    placeholder="주소를 입력하고 조회하세요!" />
                </Grid.Column>
                <Grid.Column width={5}>
                  <Button fluid icon={'search'} size={'large'} content={'조회'}
                          onClick={() => setIsPostCodeOpened(true)}
                          color={'yellow'}
                          style={{margin: 0}} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {
              (centers === null || centers.length === 0)
                ?
                (
                  <Segment placeholder style={{height: 303}}>
                    <Header icon>
                      <Icon name={'map marker alternate'} />
                      내 주소를 조회하고 내 집 앞 정비소를 확인하세요!
                    </Header>
                  </Segment>
                )
                :
                (
                  <div style={{marginTop: 16, height: 306, overflowY: 'auto'}}>
                    <Table fixed selectable compact>
                      <Table.Header />
                      <Table.Body>
                        {
                          _.map(centers, (center, idx) => (
                            <Table.Row key={`CENTER_ROW_${idx}`}>
                              <Table.Cell
                                style={{height: 40, cursor: 'pointer'}}
                                active={step3Data.center?.centerId === center.centerId}
                                onClick={() => setStep3Data(prevState => ({
                                  ...prevState,
                                  center,
                                }))}>
                                <Header as={'h5'}>
                                  <Icon
                                    name={step3Data.center?.centerId === center.centerId ? 'check circle' : 'circle outline'}
                                    color={'yellow'} />
                                  <Header.Content>
                                    {idx + 1}. {center.centerName}
                                    {step3Data.userAddress && (center.distance === 999999 ? ' (? km)' : ` (${center.distance} km)`)}
                                    <Header.Subheader style={{
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                    }}>{center.address} {center.addressDetail}</Header.Subheader>
                                  </Header.Content>
                                </Header>
                              </Table.Cell>
                            </Table.Row>
                          ))
                        }
                      </Table.Body>
                    </Table>
                  </div>
                )
            }
          </Grid.Column>
          <Grid.Column width={6}>
            <KakaoMap
              mapId={'reservation-step3-map'}
              latitude={step3Data?.center?.latitude}
              longitude={step3Data?.center?.longitude}
              draggable={!isMobile} />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header as="h3" dividing>
        <Icon name={'calendar check outline'} />
        <Header.Content>
          방문희망일시 선택
          <Header.Subheader>방문을 원하시는 날짜와 시간을 선택하세요!</Header.Subheader>
        </Header.Content>
      </Header>
      <Grid stackable>
        <Grid.Row columns={'equal'}>
          <Grid.Column>
            <DateInput
              pickerStyle={{minWidth: '100%'}}
              inline={true}
              localization={'ko'}
              dateFormat={DATE_FORMAT}
              minDate={START_DAY.format(DATE_FORMAT)}
              maxDate={MAX_DAY.format(DATE_FORMAT)}
              value={step3Data?.reservationDate || START_DAY.format(DATE_FORMAT)}
              disable={HOLIDAYS}
              onChange={(e, {value}) => setStep3Data(prevState => ({
                ...prevState,
                reservationDate: value,
              }))} />
          </Grid.Column>
          <Grid.Column>
            <Segment basic loading={isCenterIdleTimesLoading} style={{padding: 0}}>
              {
                !_.isEmpty(centerIdleTimes)
                  ?
                  (
                    <Table celled fixed unstackable>
                      <Table.Header />
                      <Table.Body>
                        {
                          _.map(_.chunk(centerIdleTimes, 4), (chunk, row) => (
                            <Table.Row textAlign={'center'} key={`TT_ROW${row}`}>
                              {
                                _.map(_.times(4), col => {
                                  return (
                                    <Table.Cell
                                      key={`TT_ROW${row}_COL${col}`}
                                      style={{height: 40, cursor: 'pointer'}}
                                      selectable
                                      active={step3Data?.reservationTime === chunk[col]?.time}
                                      disabled={chunk[col]?.isAvailable === 'N'}
                                      onClick={() => {
                                        setStep3Data(prevState => ({
                                          ...prevState,
                                          reservationTime: chunk[col]?.time,
                                        }));
                                      }}>
                                      {chunk[col]?.time}
                                    </Table.Cell>
                                  );
                                })
                              }
                            </Table.Row>
                          ))
                        }
                      </Table.Body>
                    </Table>
                  )
                  :
                  (
                    <Message>
                      <Message.Content>
                        <Message.Header>
                          <Icon name={'clock outline'} /> 정비소와 날짜를 선택하고 예약 가능한 시간을 확인해 보세요.
                        </Message.Header>
                        정비소를 변경하시면, 예약 시간을 다시 선택하셔야 합니다.
                      </Message.Content>
                    </Message>
                  )
              }
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign={'right'}>
            <Button onClick={() => {
              backToPrevStep({
                params,
                step1Data,
                step2Data,
              });
            }}>
              <Icon name={'angle left'} fitted /> 이전
            </Button>
            <Button
              color={'teal'}
              disabled={!(step3Data.center?.centerId !== 0 && step3Data.reservationDate !== '' && step3Data.reservationTime !== '')}
              onClick={() => {
                goToNextStep({
                  params,
                  step1Data,
                  step2Data,
                  step3Data,
                });
              }}>
              다음 <Icon name={'angle right'} fitted />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Modal
        closeIcon
        size={'small'}
        open={isPostCodeOpened}
        onOpen={() => setIsPostCodeOpened(true)}
        onClose={() => setIsPostCodeOpened(false)}>
        <Modal.Header>
          주소선택
        </Modal.Header>
        <Modal.Content style={{padding: 0}}>
          <DaumPostcode
            defaultQuery={step3Data.userAddress}
            onComplete={({address}) => {
              getCenter(address);
              setStep3Data(prevState => ({...prevState, userAddress: address}));
              setIsPostCodeOpened(false);
            }}
          />
        </Modal.Content>
      </Modal>
    </ReservationStepOuter>
  );
}
