import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import {
  Accordion,
  Button,
  Container,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Message,
  Modal,
  Popup,
  TextArea,
} from 'semantic-ui-react';
import { BASE_STYLES, FAQ } from '@/Consts';

import axios from 'axios';
import _ from 'lodash';
// import LogRocket from "logrocket";
import { useNavigate } from 'react-router-dom';
import Chat from '@/layout/component/Chat/Chat';
import DescriptionHeader from '@/layout/component/Main/DescriptionHeader';
import SectionHeader from '@/layout/component/Main/SectionHeader';
import MainCard from '@/layout/component/MainCard';
import styled from 'styled-components';

const MIN_WIDTH_BASE = 759;
const MAX_WIDTH_BASE = MIN_WIDTH_BASE + 1;

const CHATS = {
  INCONVENIENCE: [
    [[], ['동윤아, 너 얼마전에 차 팔았다며? 잘 팔았어?']],
    [['와...말도마라, 차 팔면서 내가 10년은 늙었어.'], []],
    [[], ['오잉? 왜?']],
    [['이왕 팔거, 좀더 비싸게 팔려고, 이곳저곳 비교 했었거던…'], []],
    [[], ['아, 내차팔기 어플 이용했구나?']],
    [['응, 그런데 가격은 둘째 치더라도, 너무 불편하고 한편으론 뻘쭘했어.'], []],
    [[], ['이런, 어떤점이 그랬는데??']],
    [['음… 어플마다 좀 다르긴 했는데'], []],
    [
      [
        '어떤 어플은 차 정보랑 사진 찍어 경매 올리니, 딜러들이 와서 깎아 달라고 연실 졸라 대고,',
        '어떤 어플은 평가사라는 사람이 차를 보러 온대서 주차장에서 30분 정도 뻘쭘하게 서 있었지.',
        '어떤 어플은 바로 매입해 준다 길래 아싸 했는데, 내차는 오래되서 안된다나 뭐라나...',
      ],
      [],
    ],
    [[], ['ㅋㅋ 쉬운게 없구만, 그래서 차는 팔긴 했어? 어디에 팔았는데?']],
    [['카몬.'], []],
    [[], ['엥? 카몬???']],
  ],
  PRICE: [
    [[], ['카몬? 처음 들어보는데?']],
    [
      [
        '형근아, 내가 딜러한테 몇번 감가 시도 당하면서 시달리고',
        '눈으로 훑어보는 평가사가 매긴 가격은 썩 맘에 안 들고',
        '그러니까 이게 오기가 생기더라고.',
        '그래서, 인터넷에서 다른 내 차 팔기 서비스는 없나 싶어서 기사도 찾아보고 막 검색을 해봤지.',
      ],
      [],
    ],
    [[], ['그래서 찾은게 카몬?']],
    [['빙고! 여기는 편한게, 그냥 우리집 근처 정비소에 차만 끌고가면 되더라고'], []],
    [[], ['엥? 뭔 소리야 갑자기.', '차를 니가 왜 끌고가. 게다가 정비소에.', '그게 더 불편한거 아냐?']],
    [['아냐아냐, 봐봐.', '내가 집으로 부르는게 편할 것 같아서 불렀었는데', '생각보다 엄청 불편하다고 그랬잖아.'], []],
    [[], ['ㅇㅇ 그랬었지.']],
    [
      [
        '근데 속는 셈 치고 카몬에서 예약한 정비소로 차를 끌고 갔지.',
        '우리집 근처에 내가 11년째 가고 있는 정비소도 진단을 하더라고?',
        '우리집에서 5분도 안걸리는데 있는거.',
        '그래서 차 끌고 가서 진단받고 집에 왔더니 경매가 등록됐더라고.',
      ],
      [],
    ],
    [['게다가 하루 만에 경매가 끝났는데도, 가격이 제일 높았지.'], []],
    [
      [],
      [
        '딜러들도 정비소 진단 결과를 보고 경매에 참여할테니까',
        '가격이 높아지겠네.',
        '딜러들 입장에서도, 너는 못믿어도 정비소 진단 결과는 믿겠지 ㅋ',
      ],
    ],
    [['ㅇㅇ 그렇겠지 아무래도. ㅋㅋ'], []],
    [[], ['오...듣고보니 카몬 괜찮은 것 같은데?', '안그래도 나도 차 바꿀까 하는데, 카몬에서 팔아야겠다.']],
    [['ㅇㅇ 진짜 추천한다.', '내가 웬만하면 추천 같은거 잘 안하잖냐.'], []],
    [[], ['오키 믿어본다.', '근데...돈 생겼는데 한잔 쏘냐?']],
    [['싫은데? ㅋㅋ'], []],
  ],
};

const MAIN_CARDS = {
  INCONVENIENCE: [
    {
      image: '/images/main/1-1.png',
      headers: ['복잡한', '내 차 정보 입력'],
      subheaders: ['카몬이 해드립니다.'],
      subheaderColor: '#be1e00',
      descriptions: [
        [
          [
            '내가 내 차 파는데, 직접 앱 설치하고, 차량 사진도 찍고, 차량 옵션과 사고 조회 확인하기 등등, 귀찮고 어렵고 지치네요.',
          ],
          [],
        ],
        [['매일 타는 차지만, 차량 옵션이나 사고, 수리 내역을 일일이 어떻게 다 기억하나요.'], []],
        [['요새는 감가 당하지 않으려면 더더욱 꼼꼼히 입력해야 한다던데...😭'], []],
        [['카몬에서는 다를까요?'], []],
        [[], ['네! 맞습니다!', '카몬에서는 이 모든 일을 국가공인 정비사가 객관적이고, 정확하게 해 드립니다.']],
        [[], ['내 차 팔 때, 불편함', '카몬이 해드립니다.']],
      ],
    },
    {
      image: '/images/main/1-2.png',
      headers: ['뻘쭘뻘쭘', '딜러/평가사 대면'],
      subheaders: ['카몬에는 없습니다.'],
      subheaderColor: '#be1e00',
      descriptions: [
        [
          ['다른 서비스에선, 내 차를 사겠다고 직접 보러 온다는데,', '딜러를 직접 만나는 것도, 약속 잡기도 어렵네요.'],
          [],
        ],
        [
          [
            '차량 평가사분은 좀 나을까 했는데,',
            '차를 보는 시간 내내 차 옆에서 기다리고 있는 것도 여간 불편한게 아니네요.',
          ],
          [],
        ],
        [['카몬에서는 다를까요?'], []],
        [[], ['네. 카몬에선, 딜러도 평가사도 만날 필요가 없습니다.']],
        [[], ['집 근처 카몬 제휴 정비소에 차를 맡기기만 하세요.', '편안하게 차 한 잔 하면서 기다리기만 하면 됩니다.']],
        [[], ['내 차 팔 때, 뻘쭘함', '카몬에는 없습니다.']],
      ],
    },
    {
      image: '/images/main/1-3.png',
      headers: ['감가 핑계,', '자동차 용어?'],
      subheaders: ['카몬이 차단합니다.'],
      subheaderColor: '#be1e00',
      descriptions: [
        [['딜러가 와서 차를 보더니', '이런 저런 어려운 이야기로, 내 차 가격을 너무 깎으려고만 하네요.'], []],
        [['저는 차를 잘 몰라서 그냥 알았다고만 했는데, 엄청 많이 깎았더라구요.'], []],
        [['카몬에서는 다를까요?'], []],
        [
          [],
          ['네. 카몬에선 딜러를 직접 대면할 일이 없습니다.', '사전에 받은 차량 진단 결과로 감가 여지를 차단합니다.'],
        ],
        [[], ['내 차 팔 때, 감가 핑계', '카몬이 차단합니다.']],
      ],
    },
  ],
  PRICE: [
    {
      image: '/images/main/2-1.png',
      headers: ['국가공인 정비소', '차량진단'],
      subheaders: ['카몬은 정확합니다.'],
      headerColor: '#a0a0a0',
      subheaderColor: BASE_STYLES.THEME_SUB_COLOR,
      descriptions: [
        [['카몬의 차량진단이 차량평가사 진단과 뭐가 다르죠?', '차량평가사가 자신을 차량전문가라고 하시던데요?'], []],
        [
          [],
          [
            '카몬의 차량진단은, 국가공인정비소에서 객관적 장비를 통해 정확한 진단이 이루어 집니다.',
            '눈으로만 하는 평가사 진단과는 하늘과 땅 차이죠. 차량 성능은 물론, 외관, 하부까지도 꼼꼼히 진단을 시행합니다.',
          ],
        ],
        [['아하! 그래서 딜러가 차량을 보러 저한테 찾아올 이유가 없는 거네요?'], []],
        [
          [],
          [
            '네! 맞습니다. 편하실 때 집 근처 카몬 제휴정비소에 차량을 맡겨만 주세요.',
            '차량 진단과 리포트 작성은 카몬이 대신합니다.',
          ],
        ],
      ],
    },
    {
      image: '/images/main/2-2.png',
      headers: ['감가 無,', '최고가 낙찰 경매'],
      subheaders: ['카몬은 똘똘합니다.'],
      headerColor: '#a0a0a0',
      subheaderColor: BASE_STYLES.THEME_SUB_COLOR,
      descriptions: [
        [['카몬에선 정말 최고가에서 감가없이 내차 판매가 가능한가요?'], []],
        [
          [],
          [
            '네! 카몬은 정비소의 정확한 차량 진단 정보를 바탕으로,',
            '딜러는 차량을 보러 판매자님을 대면할 필요가 없고,',
            '눈으로만 하는 잘못된 진단으로 인한 수리비 폭탄의 걱정도 없어서,',
            '딜러 자신이 매입할 수 있는 최고 금액으로 자신있게 입찰 합니다.',
          ],
        ],
        [['아하! 그렇다면 정말 최고가가 가능하겠네요?'], []],
        [[], ['네! 맞습니다. 딜러 수수료도 다른 앱보다 낮으니, 딜러는 카몬을 훨씬 더 선호하겠죠?']],
      ],
    },
    {
      image: '/images/main/2-3.png',
      headers: ['수수료?', '전혀 없습니다!'],
      subheaders: ['카몬에 진단 예약만 하세요!'],
      headerColor: '#a0a0a0',
      subheaderColor: BASE_STYLES.THEME_SUB_COLOR,
      descriptions: [
        [['카몬에서 내차 팔 때, 수수료가 정말 없나요?'], []],
        [[], ['네. 카몬은 다른 내 차 팔기 앱들과 달리 내 차를 파는 고객님은 수수료가 일체 없습니다.']],
        [[], ['그저 집 근처 정비소에서 커피 한잔의 여유를 즐길 시간 정도만 준비하시면 됩니다.']],
        [['와우! 카몬을 안 쓸 이유가 없네요?'], []],
        [[], ['그렇습니다! 지금 바로! 집 근처 카몬 정비소에서 편하게 진단 받고 내 차 최고가에 판매하세요!']],
      ],
    },
  ],
};

export const ServiceCarmon = () => {
  const navigate = useNavigate();

  const [currentY, setCurrentY] = useState(0);
  const [isScrollStopped, setIsScrollStopped] = useState(false);
  const [activeFAQIdx, setActiveFAQIdx] = useState(-1);

  const [isStep1, setIsStep1] = useState(true);

  const [isInconvenienceChatOpened, setIsInconvenienceChatOpened] = useState(false);
  const [isPriceChatOpened, setIsPriceChatOpened] = useState(false);

  const [isQnaOpened, setIsQnaOpened] = useState(false);
  const [isQnaPosting, setIsQnaPosting] = useState(false);
  const [qnaPostingSuccess, setQnaPostingSuccess] = useState(false);
  const [qna, setQna] = useState({
    name: '',
    contact: '',
    content: '',
  });

  //const [showDeliveryBanner, setShowDeliveryBanner] = useState(false);

  const postQna = () => {
    if (_.trim(qna.name) === '') return false;
    if (_.trim(qna.contact) === '') return false;
    if (_.trim(qna.content) === '') return false;

    setIsQnaPosting(true);

    axios
      .post('/apis/reservation/qna', qna)
      .then(({ data }) => {
        setQnaPostingSuccess(true);
        setQna({
          name: '',
          contact: '',
          content: '',
        });
      })
      .catch(({ response }) => {
        const { status, data } = response;
      })
      .finally(() => {
        setIsQnaPosting(false);
        setTimeout(() => setQnaPostingSuccess(false), 2000);
      });
  };

  const onFAQTitleClicked = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeFAQIdx === index ? -1 : index;
    setActiveFAQIdx(newIndex);
  };

  const goToReservation = () => {
    navigate('/reservation', { state: null });
  };

  return (
    <div>
      <Section $padding='0 0 4rem 0'>
        <Wrapper>
          <FlexBox>
            <ServiceItem>
              <p>딜러에게 견적받기</p>
              <p> 예약하고 방문하면 전국 딜러들에게 최고가 경매가 하루 만에 끝</p>
            </ServiceItem>
            <ServiceItem>
              <p>개인 간 거래하기</p>
              <p>예약하고 방문 진단하면 1개월 2천킬로 보장되는 전국 최초 개인 거래 보장 서비스</p>
            </ServiceItem>
          </FlexBox>
        </Wrapper>
      </Section>

      <section id={'main'} style={{ marginTop: BASE_STYLES.NAVBAR_HEIGHT * -1 }}>
        {/* CONTENT */}
        <div>
          <div
            id={'about'}
            className={'index-row'}
            style={{
              padding: isMobile ? '8px 0 24px 0' : 24,
              backgroundColor: '#F8F9FB',
            }}
          >
            <Container className='content-container' fluid>
              <div className={'inner-content'} style={{ paddingBottom: '1rem' }}>
                <Grid stackable>
                  <Grid.Row columns={'equal'} centered>
                    <Grid.Column>
                      <div
                        className='main-title'
                        style={{
                          //   color: "black !important",
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '2rem 0',
                        }}
                      >
                        <div>
                          카몬, <span style={{ color: '#ae0000' }}>불편</span>을 빼다.
                        </div>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={'equal'} centered stretched>
                    {_.map(MAIN_CARDS.INCONVENIENCE, (item, idx) => (
                      <Grid.Column key={`COL_I${idx}`}>
                        <MainCard {...item} />
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                  <Grid.Row columns={'equal'} style={{ marginTop: '1rem' }}>
                    <Grid.Column>
                      <div
                        className={'main-description'}
                        style={{
                          //   color: "#ffffff",
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          lineHeight: 1.3,
                          padding: '5rem 0',
                        }}
                      >
                        <div>지금까지의 내 차 팔기는?</div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 16,
                          }}
                        >
                          <motion.div
                            animate={{
                              scale: [1, 1.6, 1, 1.6, 1],
                              color: [
                                'hsl(0, 100%, 34%)',
                                'hsl(0, 100%, 45%)',
                                'hsl(0, 100%, 34%)',
                                'hsl(0, 100%, 45%)',
                                'hsl(0, 100%, 34%)',
                              ],
                            }}
                            transition={{
                              duration: 1.2,
                              repeat: Infinity,
                              repeatDelay: 0.8,
                            }}
                          >
                            <Icon name={'talk'} fitted link onClick={() => setIsInconvenienceChatOpened(true)} />
                          </motion.div>
                          <div>
                            <Icon name={'hand point left'} /> {isDesktop ? '클릭하세요' : '탭하세요'}!
                          </div>
                        </div>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>

              <Modal
                basic
                closeIcon
                size={'tiny'}
                open={isInconvenienceChatOpened}
                onOpen={() => setIsInconvenienceChatOpened(true)}
                onClose={() => setIsInconvenienceChatOpened(false)}
              >
                <Modal.Header>
                  <Header as={'h3'} inverted>
                    <Icon name={'question'} />
                    <Header.Content>
                      생각해 보셨나요?
                      <Header.Subheader>지금까지의 내 차 팔기, 정말 편한가요?</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Modal.Header>
                <Modal.Content>
                  <Chat title={'우리 유니'} data={CHATS.INCONVENIENCE} />
                </Modal.Content>
              </Modal>
            </Container>
          </div>

          <div
            className={'index-row'}
            style={{
              padding: isMobile ? '8px 0 24px 0' : 24,
              backgroundColor: '#F8F9FB',
            }}
          >
            <Container className={'content-container'} fluid>
              <div className={'inner-content'} style={{ paddingBottom: '1rem' }}>
                <Grid stackable>
                  <Grid.Row columns={'equal'} centered style={{ marginBottom: 'clamp(1rem, 5vh, 2.8rem)' }}>
                    <Grid.Column
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        className='main-title'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 12,
                        }}
                      >
                        <div
                          style={{
                            color: BASE_STYLES.THEME_BG_COLOR,
                            wordWrap: 'break-word',
                            wordBreak: 'keep-all',
                          }}
                        >
                          카몬, <span style={{ color: '#FEB948' }}>가격</span>만 올리다.
                        </div>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={'equal'} centered stretched>
                    {_.map(MAIN_CARDS.PRICE, (item, idx) => (
                      <Grid.Column key={`COL_P${idx}`}>
                        <MainCard {...item} />
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                  <Grid.Row columns={'equal'} style={{ marginTop: '1rem' }}>
                    <Grid.Column>
                      <div
                        className={'main-description'}
                        style={{
                          color: '#696969',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          lineHeight: 1.3,
                          padding: '5rem 0',
                        }}
                      >
                        <div>그럼, 카몬에서 내 차 팔기는?</div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 16,
                          }}
                        >
                          <motion.div
                            animate={{
                              scale: [1, 1.6, 1, 1.6, 1],
                              color: [
                                'hsl(37, 99%, 64%)',
                                'hsl(37, 98%, 76%)',
                                'hsl(37, 99%, 64%)',
                                'hsl(37, 98%, 76%)',
                                'hsl(37, 99%, 64%)',
                              ],
                            }}
                            transition={{
                              duration: 1.2,
                              repeat: Infinity,
                              repeatDelay: 0.8,
                            }}
                          >
                            <Icon name={'talk'} fitted link onClick={() => setIsPriceChatOpened(true)} />
                          </motion.div>
                          <div>
                            <Icon name={'hand point left'} /> {isDesktop ? '클릭하세요' : '탭하세요'}!
                          </div>
                        </div>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>

              <Modal
                basic
                closeIcon
                size={'tiny'}
                open={isPriceChatOpened}
                onMount={() => setIsPriceChatOpened(true)}
                onClose={() => setIsPriceChatOpened(false)}
              >
                <Modal.Header>
                  <Header as={'h3'} inverted>
                    <Icon name={'question'} />
                    <Header.Content>
                      생각해 보셨나요?
                      <Header.Subheader>내 차 팔기, 새로운 경험을 드립니다.</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Modal.Header>
                <Modal.Content>
                  <Chat title={'우리 유니'} data={CHATS.PRICE} />
                </Modal.Content>
              </Modal>
            </Container>
          </div>

          <div id={'howto'} className={'index-row'} style={{ padding: isMobile ? '24px 0' : 24 }}>
            <Container className={'content-container'} fluid>
              <div className={'inner-content'}>
                <SectionHeader
                  header={'그래서, 카몬은 이렇게 합니다.'}
                  subheader={'내 차 팔기, 새로운 방법을 제안합니다.'}
                />
                <Grid stackable className={'inner-grid'}>
                  <Grid.Row columns={'equal'} style={{ marginBottom: 22 }}>
                    <Grid.Column>
                      <div
                        style={{
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-start',
                          gap: isMobile ? 10 : 40,
                        }}
                      >
                        <div>
                          <div className={'description-number'}>01</div>
                          <DescriptionHeader
                            header={['1분 만에 예약하고,', '내 집 앞 정비소로 방문하세요.']}
                            subheader={['언제 어디서나 무료로 차량 진단을 받으실 수 있어요.']}
                            description={[
                              <>
                                카몬은 현재 전국 900여개 정비소와 차량 진단 업무 제휴를 맺고 있으며, 전국 2000여개
                                정비소 네트워크를 추가로 구축하고 있습니다. 카몬을 이용하시는 고객님은 전국 어디서든
                                15분 이내, 내 집 앞 정비소에 방문하셔서 차량 진단을 받으실 수 있습니다.
                              </>,
                            ]}
                          />
                        </div>
                      </div>
                    </Grid.Column>
                    <Grid.Column
                      only={'computer tablet'}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundImage: 'url(/images/main/3-1.png)',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                      }}
                    />
                    <Grid.Column
                      only={'mobile'}
                      style={{
                        height: 220,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundImage: 'url(/images/main/3-1.png)',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                      }}
                    />
                  </Grid.Row>

                  <Grid.Row columns={'equal'} style={{ marginBottom: 22 }}>
                    <Grid.Column
                      only={'computer tablet'}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundImage: 'url(/images/main/3-2.png)',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                      }}
                    />
                    <Grid.Column>
                      <div
                        style={{
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-start',
                          gap: isMobile ? 10 : 40,
                        }}
                      >
                        <div>
                          <div className={'description-number'}>02</div>
                          <DescriptionHeader
                            header={['예약한 정비소를 방문하셔서', '전문 정비사의 차량 진단을 받으세요.']}
                            subheader={['국가 공인 정비사가 내 차 상태를 정확하고 객관적으로 평가해드려요.']}
                            description={[
                              '예약하신 정비소에 방문하시면 전문 정비사가 차량 내·외부 촬영을 포함, 법적 성능 확인 절차에 준하여 내 차 상태를 정확하고 객관적으로 진단합니다. 또한, 진단이 완료되면, 고객님께 차량 진단 리포트를 제공해 드립니다. 카몬에서는 진단 데이터를 기반으로 하여 감가 없는 투명한 판매를 진행합니다. 즉, 객관적 진단 데이터가 고객님께 최고가를 보장해 드립니다.',
                            ]}
                          />
                        </div>
                      </div>
                    </Grid.Column>
                    <Grid.Column
                      only={'mobile'}
                      style={{
                        height: 220,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundImage: 'url(/images/main/3-2.png)',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                      }}
                    />
                  </Grid.Row>

                  <Grid.Row columns={'equal'} style={{ marginBottom: 22 }}>
                    <Grid.Column>
                      <div
                        style={{
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-start',
                          gap: isMobile ? 10 : 40,
                        }}
                      >
                        <div>
                          <div className={'description-number'}>03</div>
                          <DescriptionHeader
                            header={['경매 종료 후', '"진짜" 내 차 최고가를 확인하세요.']}
                            subheader={[
                              '오후 1시 이전에 진단을 받으신다면? 진단 받은 바로 그 날 즉시! 판매도 가능해요.',
                            ]}
                            description={[
                              '경매는 매일 진행되며, 판매 대상 차량은 오전 10시, 오후 1시에 등록됩니다. 고객님 차량의 경매가 시작되면 알림톡으로 즉시 안내해 드립니다. 경매 진행 상황은 받아보신 알림톡을 통해 언제든 확인하실 수 있습니다. 경매가 완료된 후 판매를 확정하시면, 카몬에서 차량의 탁송까지 진행해 드립니다. 차량 판매 대금은 탁송이 시작되는 즉시 지급해 드립니다.',
                              '(오후 1시 이후에 진단 결과가 등록된 차량은 다음 날 오전 10시에 경매에 등록됩니다.)',
                            ]}
                          />
                        </div>
                      </div>
                    </Grid.Column>
                    <Grid.Column
                      only={'computer tablet'}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundImage: 'url(/images/main/3-3.png)',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                      }}
                    />
                    <Grid.Column
                      only={'mobile'}
                      style={{
                        height: 220,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundImage: 'url(/images/main/3-3.png)',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                      }}
                    />
                  </Grid.Row>
                </Grid>
              </div>
            </Container>
          </div>

          <div className={'index-row'} style={{ padding: isMobile ? '24px 0' : 24 }}>
            <Container className={'content-container'} fluid>
              <div className={'inner-content'}>
                <SectionHeader header={'간단히 정리해 드립니다!'} subheader={'세 컷이면 설명 끝!'} />
                <Grid stackable className={'inner-grid'} style={{ margin: 0 }}>
                  <Grid.Row columns={'equal'}>
                    <Grid.Column
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div className={'slam-card'} style={{ backgroundColor: '#ffffff' }}>
                        <div
                          style={{
                            backgroundImage: 'url(/images/main/3-1.png)',
                          }}
                        />
                        <Header
                          as={'h2'}
                          style={{
                            marginTop: 8,
                            fontFamily: ['Pretendard'],
                            fontWeight: 900,
                          }}
                        >
                          예약하고
                        </Header>
                      </div>
                    </Grid.Column>
                    <Grid.Column
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div className={'slam-card'} style={{ backgroundColor: '#ffffff' }}>
                        <div
                          style={{
                            backgroundImage: 'url(/images/main/3-2.png)',
                          }}
                        />
                        <Header
                          as={'h2'}
                          style={{
                            marginTop: 8,
                            fontFamily: ['Pretendard'],
                            fontWeight: 900,
                          }}
                        >
                          방문하면
                        </Header>
                      </div>
                    </Grid.Column>
                    <Grid.Column
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div className={'slam-card'} style={{ backgroundColor: '#ffffff' }}>
                        <div
                          style={{
                            backgroundImage: 'url(/images/main/3-3.png)',
                          }}
                        />
                        <Header
                          as={'h2'}
                          style={{
                            marginTop: 8,
                            fontFamily: ['Pretendard'],
                            fontWeight: 900,
                          }}
                        >
                          끝!
                        </Header>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </Container>
          </div>

          <div id={'reservation'} className={'index-row'} style={{ padding: isMobile ? '24px 0' : '3rem' }}>
            <Container className={'content-container'} fluid>
              <div className={'inner-content'}>
                <SectionHeader header={'내 차 진단 받으시려면?'} subheader={'더불어 내 차 정보·시세 확인까지!'} />
                <motion.div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignSelf: 'stretch',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onTap={() => goToReservation()}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <Button
                    content={'지금 예약하세요!'}
                    fluid
                    size={'massive'}
                    primary
                    icon={'hand point up'}
                    style={
                      !isMobile
                        ? {
                            width: '80%',
                            padding: '2rem',
                            fontSize: '2.4rem',
                            backgroundColor: 'rgba(0, 174, 217, 0.9)',
                          }
                        : { backgroundColor: 'rgba(0, 174, 217, 0.9)' }
                    }
                  />
                </motion.div>
              </div>
            </Container>
          </div>

          <div id={'faq'} className={'index-row'} style={{ padding: isMobile ? '24px 0' : '3rem' }}>
            <Container className={'content-container'} fluid>
              <div className={'inner-content'}>
                <Grid stackable>
                  <Grid.Row columns={2}>
                    <Grid.Column width={10}>
                      <SectionHeader header={'자주 묻는 질문'} subheader={'카몬을 이용하실 때 알아두시면 좋습니다.'} />
                      <Accordion styled fluid exclusive={false}>
                        {_.map(FAQ, (row, idx) => (
                          <React.Fragment key={`panel-${idx}`}>
                            <Accordion.Title
                              style={{ fontSize: BASE_STYLES.FONT_SIZE.SUB }}
                              index={idx}
                              active={activeFAQIdx === idx}
                              onClick={onFAQTitleClicked}
                            >
                              <Icon name='dropdown' />
                              {row[0]}
                            </Accordion.Title>
                            <Accordion.Content
                              style={{ fontSize: BASE_STYLES.FONT_SIZE.SUB }}
                              active={activeFAQIdx === idx}
                            >
                              <div dangerouslySetInnerHTML={{ __html: row[1] }} />
                            </Accordion.Content>
                          </React.Fragment>
                        ))}
                      </Accordion>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <SectionHeader header={'서비스 문의'} subheader={'카몬에 궁금하신 점이 있다면 문의주세요!'} />
                      <Form loading={isQnaPosting} success={qnaPostingSuccess}>
                        <Form.Field>
                          <label>문의자명</label>
                          <Input
                            style={{ fontSize: BASE_STYLES.FONT_SIZE.SUB }}
                            value={qna.name}
                            placeholder={'문의하시는 분 이름'}
                            icon={
                              qna.name !== '' && (
                                <Icon
                                  name='remove'
                                  link
                                  onClick={() =>
                                    setQna((prevState) => ({
                                      ...prevState,
                                      name: '',
                                    }))
                                  }
                                />
                              )
                            }
                            onChange={(e, { value }) => {
                              setQna((prevState) => ({
                                ...prevState,
                                name: value,
                              }));
                            }}
                          />
                        </Form.Field>
                        <Form.Field>
                          <label>연락처</label>
                          <Input
                            style={{ fontSize: BASE_STYLES.FONT_SIZE.SUB }}
                            value={qna.contact}
                            onChange={(e, { value }) => {
                              const refined = value
                                .replace(/[^0-9]/g, '')
                                .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3')
                                .replace('--', '-');

                              setQna((prevState) => ({
                                ...prevState,
                                contact: refined,
                              }));
                            }}
                            maxLength={13}
                            placeholder='문의하시는 분 연락처'
                            icon={
                              qna.contact !== '' && (
                                <Icon
                                  name='remove'
                                  link
                                  onClick={() =>
                                    setQna((prevState) => ({
                                      ...prevState,
                                      contact: '',
                                    }))
                                  }
                                />
                              )
                            }
                          />
                        </Form.Field>
                        <Form.Field>
                          <label>문의내용</label>
                          <TextArea
                            style={{ fontSize: BASE_STYLES.FONT_SIZE.SUB }}
                            placeholder={'무엇이 궁금하신가요?'}
                            rows={5}
                            value={qna.content}
                            onChange={(e, { value }) => {
                              setQna((prevState) => ({
                                ...prevState,
                                content: value,
                              }));
                            }}
                          />
                        </Form.Field>
                        {!qnaPostingSuccess && (
                          <Message
                            icon={'question circle'}
                            header={'무엇이든 물어보세요!'}
                            content={'확인 후 빠른 시일 내에 연락드리겠습니다.'}
                          />
                        )}
                        <Message
                          success
                          icon={'smile'}
                          header={'접수완료'}
                          content={'고객님의 문의가 접수되었습니다.'}
                        />
                        <Popup
                          wide
                          open={isQnaOpened}
                          onOpen={() => setIsQnaOpened(true)}
                          onClose={() => setIsQnaOpened(false)}
                          inverted
                          position={'top right'}
                          trigger={
                            <Button
                              fluid
                              content={'카몬에 문의하기'}
                              color={'yellow'}
                              loading={isQnaPosting}
                              disabled={
                                !(_.trim(qna.name) !== '' && _.trim(qna.contact) !== '' && _.trim(qna.content) !== '')
                              }
                              onClick={() => setIsQnaOpened(true)}
                            />
                          }
                          on='click'
                        >
                          <Header>
                            <Icon name={'send'} />
                            <Header.Content>
                              문의사항을 등록하시겠습니까?
                              <Header.Subheader>정확한 연락을 위해 연락처를 꼭 확인해주세요.</Header.Subheader>
                            </Header.Content>
                          </Header>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button content={'아니오'} onClick={() => setIsQnaOpened(false)} />
                            <Button
                              icon={'check'}
                              content={'네'}
                              primary
                              onClick={() => {
                                setIsQnaOpened(false);
                                postQna();
                              }}
                            />
                          </div>
                        </Popup>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </Container>
          </div>
        </div>

        {((isMobile && currentY > document.querySelector('#about')?.getBoundingClientRect().y) || !isMobile) && (
          <>
            <Button
              color={'red'}
              circular
              labelPosition={'left'}
              content='지금 내 차 팔기'
              size={'large'}
              label={{
                basic: true,
                pointing: 'right',
                content: <Icon name={'calendar check outline'} size={'large'} fitted />,
                color: 'red',
              }}
              style={{
                opacity: isScrollStopped ? 1 : 0,
                position: 'fixed',
                zIndex: 950,
                bottom: '2vh',
                right: '1vh',
                boxShadow: '1px 1px 5px #00000055',
                transition: 'all .5s ease-in-out',
              }}
              onClick={() => {
                navigate('/reservation', { state: null });
              }}
            />
          </>
        )}
      </section>
    </div>
  );
};

const Section = styled.section`
  display: flex;
  justify-content: center;

  background: ${(props) => props.$background || 'white'};
  padding: ${(props) => props.$padding || '5.5rem 3rem'};
  text-align: center;

  @media only screen and (max-width: 600px) {
    padding: 2rem 1.2rem;
  }

  span {
    font-weight: inherit;
  }
`;

const Wrapper = styled.div`
  flex: 1;
  max-width: 1280px;
  overflow: hidden;
`;

const FlexBox = styled.div`
  display: flex;

  padding: 2rem 0;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

const ServiceItem = styled.div`
  /* border: 2px solid black; */
  background-color: white;
  border-radius: 10px;
  margin: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1000px) {
    margin-bottom: 1rem;
    /* padding: 1.2rem; */
    font-size: 1.2rem;
  }

  p:first-child {
    font-size: 1.4rem;
    font-weight: bold;
  }

  p:nth-child(2) {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.4;
    padding-top: 1rem;

    height: 5rem;

    @media only screen and (max-width: 1000px) {
      height: 4rem;
    }
  }
`;
