import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, Header, Icon, Message, Segment, Table } from 'semantic-ui-react';
import { ReservationContext as ReservationStore } from '../ReservationPopup';
import { motion, useAnimation } from 'framer-motion';
import _, { last } from 'lodash';
import moment from 'moment';
import KakaoMap from '../../KakaoMap';
import ReservationStepOuter from '../ReservationStepOuter';
import ReservationContext from '../../../../stores/ReservationContext';
import { useLocation, useNavigate } from 'react-router-dom';

const MY_STEP = 4;

export default function ReservationStep5() {
  const { currentStep, resetStep, backToPrevStep, setToMyStep, goToNextStep, buildButtons } =
    useContext(ReservationContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setToMyStep(MY_STEP);
  }, []);

  useEffect(() => {
    if (process.env.RUN_MODE === 'production') {
      var _nasa = {};
      if (window.wcs) _nasa['cnv'] = wcs.cnv('1', '10'); // 전환유형, 전환가치 설정해야함. 설치매뉴얼 참고
    }
  }, []);

  useEffect(() => {
    buildButtons([]);
  }, []);

  const [data, setData] = useState({
    reservationNumber: '',
    registerName: '',
    registerContact: '',
    reservationDate: '',
    reservationTime: '',
  });
  const [paymentInfo, setPaymentInfo] = useState({});
  useEffect(() => {
    if (location.state) {
      const { step2Data, step3Params, paymentInfo } = location.state || {};

      setData({
        reservationNumber: paymentInfo.ordNo,
        registerName: step3Params.carOwnerName,
        registerContact: step3Params.registerContact,
        reservationDate: step2Data.reservationDate,
        reservationTime: step2Data.reservationTime,
      });
      setPaymentInfo(paymentInfo);
    }
  }, [location.state]);

  return (
    <ReservationStepOuter myStep={MY_STEP}>
      <Header as={'h3'}>
        <Header.Content>
          카몬을 이용해 주셔서 감사합니다! <Icon name={'smile'} fitted />
          <Header.Subheader>카몬에서 진정한 비대면 내 차 팔기를 경험해보세요!</Header.Subheader>
        </Header.Content>
      </Header>

      <Table compact stackable>
        <Table.Header />
        <Table.Body>
          <Table.Row>
            <Table.Cell width={4} style={{ fontWeight: 'bold' }} verticalAlign={'top'}>
              예약번호
            </Table.Cell>
            <Table.Cell width={12}>{_.get(data, 'reservationNumber')}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={4} style={{ fontWeight: 'bold' }} verticalAlign={'top'}>
              예약일시
            </Table.Cell>
            <Table.Cell width={12}>
              {moment(_.get(data, 'reservationDate')).format('YYYY년 MM월 DD일')}, {_.get(data, 'reservationTime')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={4} style={{ fontWeight: 'bold' }} verticalAlign={'top'}>
              예약자
            </Table.Cell>
            <Table.Cell width={12}>
              {_.get(data, 'registerName')} ({_.get(data, 'registerContact')})
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={4} style={{ fontWeight: 'bold' }} verticalAlign={'top'}>
              결제정보
            </Table.Cell>
            <Table.Cell width={12}>
              <p>
                - 결제금액: {_.get(paymentInfo, 'amount')}
                {'원'}
              </p>
              <p>- 은행명 : {_.get(paymentInfo, 'bankName')}</p>
              <p>- 가상계좌번호 : {_.get(paymentInfo, 'vacntNo')}</p>
            </Table.Cell>
          </Table.Row>
          {/* <Table.Row>
            <Table.Cell width={4} style={{ fontWeight: 'bold' }} verticalAlign={'top'}>
              방문정비소
            </Table.Cell>
            <Table.Cell width={12}>
              {_.get(data, 'center.name')}
              <br />({_.get(data, 'center.address')} {_.get(data, 'center.addressDetail')})
            </Table.Cell>
          </Table.Row> */}
          <Table.Row>
            <Table.Cell width={4} style={{ fontWeight: 'bold' }} verticalAlign={'top'}>
              등록일시
            </Table.Cell>
            <Table.Cell width={12}>{moment(_.get(data, 'registerTime')).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Message
        icon={'announcement'}
        header={'카몬에 예약을 접수하신 고객님께서 하실 일은?'}
        content={'카카오 알림톡으로 전달되는 카몬 채널의 메시지를 잘~ 확인해주시면 됩니다!'}
      />
      <Button
        size={'large'}
        color={'yellow'}
        fluid
        animated='fade'
        onClick={() => {
          navigate('/', { state: null, replace: true });
        }}
      >
        <Button.Content visible>예약이 완료되었습니다!!</Button.Content>
        <Button.Content hidden>
          카몬을 이용해주셔서 감사합니다!
          <Icon name={'thumbs up'} />
        </Button.Content>
      </Button>
    </ReservationStepOuter>
  );
}
