import React, { useState } from 'react';
import { Button, Modal, TransitionablePortal } from 'semantic-ui-react';
import { PRIVACY_POLICY } from '/static/privacy_policy_doc';

const CarmonPrivacyPolicy = ({ linkTitle, style }) => {
  const [open, setOpen] = useState(false);

  const handleTriggerClick = (event) => {
    setOpen(true);
  };

  const handleClose = (event, data) => {
    setOpen(false);
  };

  const { animation, duration } = { animation: 'fade down', duration: 500 };

  return (
    <TransitionablePortal
      centered={false}
      onClose={handleClose}
      onOpen={handleTriggerClick}
      open={open}
      trigger={
        <Button
          icon='file alternate'
          style={{ cursor: 'pointer', ...style }}
          onClick={handleTriggerClick}
          content={linkTitle}
        />
      }
      transition={{ animation, duration }}
    >
      <Modal open size='large'>
        <Modal.Header>개인정보 수집 및 이용 동의</Modal.Header>
        <Modal.Content scrolling style={{ padding: 0 }}>
          <iframe srcDoc={PRIVACY_POLICY} style={{ border: 'none', width: '100%', height: '60vh' }} />
        </Modal.Content>
        <Modal.Actions>
          <Button icon='times' content='닫기' onClick={handleClose} />
        </Modal.Actions>
      </Modal>
    </TransitionablePortal>
  );
};

export default CarmonPrivacyPolicy;
